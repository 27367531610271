import { faMagnifyingGlass, faBuilding, faRocket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Section } from 'components/custom/Elements'

const Twentytwentyfourvertical = () => {
  return (
    <>
      <Section title="2024" />
      <div className="d-flex flex-column w-100 component">
        <div className="d-flex flex-column justify-content-start align-items-start roadmap-pill-wrapper">
          <div className="d-flex justify-content-center align-items-center gap-5 roadmap-pill">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="fs-1" />
            <span>Phase 1 (Q1)</span>
          </div>
          <div className="roadmap-pill-content">
            <ul>
              <br />
              <li>Identify Core Technology Focus</li>
              <li>Network Architecture of SparkPoint Network</li>
              <li>Blockchain Platform Internal Development and Testing</li>
              <li>Identify innovative use cases of SRK Token in the Network</li>
              <br />
            </ul>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-start align-items-start roadmap-pill-wrapper">
          <div className="d-flex justify-content-center align-items-center gap-5 roadmap-pill">
          <FontAwesomeIcon icon={faBuilding} className="fs-1" />
            <span>Phase 2 (Q2-Q3)</span>
          </div>
          <div className="roadmap-pill-content">
            <ul className="ul-pop">
              <br />
              <li>Scalable Infrastructure Selection</li>
              <li>Testnet Deployment of SparkPoint Network.</li>
              <li>Integrate SparkPoint Ecosystem’s Core Functionalities</li>
              <li>Comprehensive Documentation of APIs and Development tools</li>
              <li>Public Pilot Testing and Bug Bounty Program</li>
              <li>Security Assessment and Enhancement</li>
              <br />
            </ul>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-start align-items-start roadmap-pill-wrapper">
          <div className="d-flex justify-content-center align-items-center gap-5 roadmap-pill">
          <FontAwesomeIcon icon={faRocket} className="fs-1" />
            <span>Phase 3 (Q4)</span>
          </div>
          <div className="roadmap-pill-content">
            <ul>
              <br />
              <li>SparkPoint Network Mainnet Launch</li>
              <li>Developer Community Engagement</li>
              <li>Enhance Developer Experience</li>
              <li>Development Grants and Airdrop for Early Adopters</li>
              <li>Network Performance and Gas Fee Optimization</li>
              <li>Node Key Launch</li>
              <li>SparkPoint Wallet Support</li>
              <li>Gaming, Infrastructure, and Ecosystem Partnerships</li>
              <li>New CEX Listing</li>
              <br />
            </ul>
          </div>
        </div>
      </div>
      <hr />
    </>
  )
}

export default Twentytwentyfourvertical
