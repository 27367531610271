import { useState, useEffect } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import logoBlack from 'assets/sparkpoint/SRK_wordmark_black.svg'
import logoWhite from 'assets/sparkpoint/SRK_wordmark.svg'
import logoSingle from 'assets/sparkpoint/sparkpoint-logo-top-one.png'
import './style.scss'
import { useStoreState } from 'easy-peasy'
import { scroller } from 'react-scroll'

const Navigation = () => {
  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [logo, setLogo] = useState(
    currentTheme === 'light' ? logoWhite : logoBlack
  )
  const [scrollPosition, setScrollPosition] = useState(0)

  /*
  const [coingeckocomp, setCoingeckocomp] = useState(
    currentTheme === 'light' ? (
      <coingecko-coin-price-marquee-widget
        coin-ids="sparkpoint,sparkpoint-fuel,binancecoin,ethereum,bitcoin,ownly,metagaming-guild"
        currency="usd"
        background-color="#ffffff"
        locale="en"
        font-color="#000000"
      ></coingecko-coin-price-marquee-widget>
    ) : (
      <coingecko-coin-price-marquee-widget
        coin-ids="sparkpoint,sparkpoint-fuel,binancecoin,ethereum,bitcoin,ownly,metagaming-guild"
        currency="usd"
        background-color="#020F23"
        locale="en"
        font-color="#ffffff"
      ></coingecko-coin-price-marquee-widget>
    )
  )
  */

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  const scrollTo = (name) => {
    scroller.scrollTo(name, {
      duration: 300,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -50
    })
  }

  useEffect(() => {
    setLogo(currentTheme === 'light' ? logoBlack : logoWhite)
    /*
    setCoingeckocomp(
      currentTheme === 'light' ? (
        <coingecko-coin-price-marquee-widget
          coin-ids="sparkpoint,sparkpoint-fuel,binancecoin,ethereum,bitcoin,ownly,metagaming-guild"
          currency="usd"
          background-color="#ffffff"
          locale="en"
          font-color="#000000"
        ></coingecko-coin-price-marquee-widget>
      ) : (
        <coingecko-coin-price-marquee-widget
          coin-ids="sparkpoint,sparkpoint-fuel,binancecoin,ethereum,bitcoin,ownly,metagaming-guild"
          currency="usd"
          background-color="#020F23"
          locale="en"
          font-color="#ffffff"
        ></coingecko-coin-price-marquee-widget>
      )
    )
    */
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [currentTheme])

  return (
    <div id="nav" className="position-fixed">
      {/*coingeckocomp*/}
      <Navbar
        collapseOnSelect
        expand="xl"
        id="navigation"
        className={`${
          scrollPosition >= 100 ? 'shadow-sm py-1' : 'pt-0'
        } position-fixed w-100`}
      >
        <Navbar.Brand>
          <Nav.Link
            href="/"
            className="d-flex justify-content-center align-items-center brand"
          >
            <img src={logo} alt="SparkPoint" className="logoText" />
            <img src={logoSingle} alt="SparkPoint" className="logo d-none" />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="justify-content-center align-items-end align-items-xl-center text-center gap-3">
            <LinkContainer
              to="/"
              onClick={() => window.scrollTo(0, 0)}
              id="home-button"
            >
              <Nav.Link>HOME</Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="#roadmap"
              onClick={() => scrollTo("roadmap-hero")}
              id="roadmap-button"
            >
              <Nav.Link>ROADMAP</Nav.Link>
            </LinkContainer>
            <LinkContainer
              to="#ecosystem"
              id="ecosystem-button"
            >
              <NavDropdown title="ECOSYSTEM" className="navigation-dropdown">
                <NavDropdown.Item href="/#token" onClick={() => scrollTo("home-token")}>SRK Token</NavDropdown.Item>
                <LinkContainer to="/#products" onClick={() => scrollTo("home-products")}>
                  <NavDropdown.Item>Products</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/#updates" onClick={() => scrollTo("home-updates")}>
                  <NavDropdown.Item>Updates</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </LinkContainer>
            <LinkContainer
              to="#about"
              id="about-us"
            >
              <NavDropdown title="ABOUT" className="navigation-dropdown">
                <NavDropdown.Item href="/#about" onClick={() => scrollTo("about-us-team")}>Our Story</NavDropdown.Item>
                <LinkContainer to="/#team" onClick={() => scrollTo("team-main")}>
                  <NavDropdown.Item>Team</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </LinkContainer>
            <LinkContainer
              to="#CONTACT US"
              onClick={() => scrollTo("partnerships-contact")}
              id="contact-us"
            >
              <Nav.Link>CONTACT US</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Navigation
