import { useEffect } from 'react'
import {
  Hero,
  Info,
  Ecosystem,
  Distribution,
  Exchanges,
  MoreInfo,
  UseSRK,
  Links
} from 'components/pages/Token'
import { scroller } from 'react-scroll'

const Token = () => {
  const checkPath = () => {
    const location = window.location.href
    const hasId = location.split('#').length > 2
    if(hasId) {
      let name;
      switch(location.split('#')[2]) {
        case 'utilities':
          name = 'token-ecosystem';
          break;
        case 'distribution':
          name = 'token-distribution';
          break;
        case 'where-to-get-srk':
          name = 'token-exchanges';
          break;
        default:
          name = '';
          break;
      }
      setTimeout(() => {
        scroller.scrollTo(name, {
          duration: 300,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -50
        })
      }, 1000);
    }
  }

  useEffect(() => {
    document.title = `SparkPoint | Token`
    document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', 'token.png')
    document.querySelectorAll('meta[property=twitter\\:image]')[0].setAttribute('content', 'token.png')
    checkPath()
  })
  return (
    <>
      <Hero />
      <Info />
      <Ecosystem />
      <Distribution />
      <Exchanges />
      <MoreInfo />
      <UseSRK />
      <Links />
    </>
  )
}

export default Token
