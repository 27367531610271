import { useState, useEffect } from 'react'
import { Socials } from 'components'
import logoBlack from 'assets/sparkpoint/SRK_wordmark_black.svg'
import logoWhite from 'assets/sparkpoint/SRK_wordmark.svg'
import { Link } from 'react-router-dom'
import './style.scss'
import { useStoreState } from 'easy-peasy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Fade } from 'react-reveal'
import { scroller } from 'react-scroll'

const Footer = () => {
  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [logo, setLogo] = useState(
    currentTheme === 'light' ? logoWhite : logoBlack
  )

  const scrollTo = (name) => {
    scroller.scrollTo(name, {
      duration: 300,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  const year = new Date().getUTCFullYear()

  useEffect(() => {
    setLogo(currentTheme === 'light' ? logoBlack : logoWhite)
  }, [currentTheme])

  return (
    <>
      <div id="footer">
        <div className="main-container d-flex flex-wrap justify-content-between">
          <Fade>
            <div className="footer-left d-flex flex-column justify-content-between">
              <a href="/" className="brand d-flex align-items-center">
                <img src={logo} alt="SparkPoint" className="logoText" />
              </a>
              <div className="d-flex flex-column gap-3">
                <Socials className="footer-socials fs-2" />
                <p>&copy; {year} SparkPoint Technologies Inc.</p>
              </div>
              <div className='footer-privacy-faqs d-flex flex-wrap justify-content-start align-items-center gap-3'>
                <Link to='/privacy' onClick={() => window.scrollTo(0, 0)} className='privacyText'>Privacy Policy</Link>
                <Link to='/faqs' onClick={() => window.scrollTo(0, 0)} className='privacyText'>FAQ</Link>
              </div>
            </div>
          </Fade>
          <div className="footer-right d-flex flex-wrap justify-content-center gap-5">
            <Fade>
              <div className="d-flex flex-column gap-3">
                <Link
                  to="/"
                  onClick={() => {
                    document.getElementById('home-button').click()
                    setTimeout(() => {
                      scrollTo('home-hero')
                    }, 100)
                  }}
                >
                  HOME
                </Link>
                <Link
                  to="/"
                  onClick={() => {
                    document.getElementById('home-button').click()
                    setTimeout(() => {
                      scrollTo('home-ecosystem')
                    }, 100)
                  }}
                >
                  ECOSYSTEM
                </Link>
                <Link
                  to="/"
                  onClick={() => {
                    document.getElementById('home-button').click()
                    setTimeout(() => {
                      scrollTo('home-products')
                    }, 100)
                  }}
                >
                  PRODUCTS
                </Link>
                <Link
                  to="/"
                  onClick={() => {
                    document.getElementById('home-button').click()
                    setTimeout(() => {
                      scrollTo('home-communities')
                    }, 100)
                  }}
                >
                  COMMUNITY
                </Link>
                <a
                  href="https://medium.com/theecosystem"
                  target="_blank"
                  rel="noreferrer"
                >
                  BLOG
                </a>
                <Link
                  to="/"
                  onClick={() => {
                    document.getElementById('home-button').click()
                    setTimeout(() => {
                      scrollTo('home-updates')
                    }, 100)
                  }}
                >
                  NEWS
                </Link>
                <Link
                  to="/"
                  onClick={() => {
                    document.getElementById('home-button').click()
                    setTimeout(() => {
                      scrollTo('partnerships-contact')
                    }, 100)
                  }}
                >
                  CONTACT
                </Link>
              </div>
              <div className="d-flex flex-column gap-3">
                <Link
                  to="/token"
                  onClick={() => {
                    window.scrollTo(0, 0)
                    document.getElementById('token-button').click()
                  }}
                >
                  SRK TOKEN
                </Link>
                <Link
                  to="/wallet"
                  onClick={() => {
                    window.scrollTo(0, 0)
                    document.getElementById('wallet-button').click()
                  }}
                >
                  SPARKPOINT WALLET
                </Link>
              </div>
              <div className="d-flex flex-column gap-3">
                <Link
                  to="#"
                  onClick={() => {
                    document.getElementById('about-us-button').click()
                    setTimeout(() => {
                      scrollTo(0, 0)
                    }, 100)
                  }}
                >
                  TEAM
                </Link>
                <Link
                  to="#roadmap"
                  onClick={() => scrollTo("roadmap-hero")
  }
                >
                  ROADMAP
                </Link>
                <Link
                  to="#about"
                  onClick={() => scrollTo("about-us-hero")}
                >
                  ABOUT US
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <div
        id="backToTop"
        className="d-flex justify-content-end align-items-center"
      >
        <div className="component d-flex gap-3">
          <Fade>
            <p className="pointer" onClick={() => window.scrollTo(0, 0)}>
              BACK TO TOP
            </p>
            <FontAwesomeIcon
              icon={faAngleUp}
              className="pointer"
              onClick={() => window.scrollTo(0, 0)}
            />
          </Fade>
        </div>
      </div>
    </>
  )
}

export default Footer
