import { LinkContainer } from 'react-router-bootstrap'
import { scroller } from 'react-scroll'
import WalletLogo from 'assets/sparkpoint/ecosystem/Wallet Logo.png'
import Wallet1 from 'assets/banner/Wallet 1.png'
import Wallet2 from 'assets/banner/Wallet 2.png'
import Wallet3 from 'assets/banner/Wallet 3.png'
import './style.scss'

const Banner = () => {
  const scrollTo = (name) => {
    scroller.scrollTo(name, {
      duration: 300,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -50
    })
  }
  return (
    <div id="banner">
      <div className="banner-wrapper px-5">
        <div className="banner-left d-flex flex-column justify-content-center align-center">
          <div className="w-100 banner-left-wrapper">
            <div className="banner-logo">
              <div className="banner-logo-wrapper">
                <img src={WalletLogo} alt="SparkPoint Wallet" />
                <span>SparkPoint Wallet</span>
              </div>
            </div>
            <div>
              <h1>Avalanche C-Chain Support is now supported!</h1>
            </div>
            <div>
              <h2>Use SparkPoint Wallet to send your Avax, USDC, and USDT!</h2>
            </div>
          </div>
        </div>
        <div className="banner-right overflow-hidden w-100">
          <div className="banner-images d-flex w-auto h-100 justify-content-center">
            <img src={Wallet1} className="wallet-1" alt="SparkPoint Wallet" />
            <img src={Wallet2} className="wallet-2" alt="SparkPoint Wallet" />
            <img src={Wallet3} className="wallet-3" alt="SparkPoint Wallet" />
          </div>
          <div className="banner-action">
            <div className="banner-action-wrapper">
              <LinkContainer to={"/wallet#download"} onClick={() => scrollTo("wallet-download")}><button>Download Version 12.1</button></LinkContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
