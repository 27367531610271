import { Section } from 'components/custom/Elements'
import srkToken from 'assets/token/hero/SparkPoint Token.png'
import './style.scss'
import { Fade } from 'react-reveal'
import { useStoreState } from 'easy-peasy'

const Hero = () => {
  const supply = useStoreState((state) => state.supply.amount)
    .toString()
    .slice(0, 4)
  const units = useStoreState((state) => state.supply.units)
  return (
    <div id="token-hero">
      <div className="token-hero-wrapper d-flex flex-column justify-content-center align-content-center">
        <Section
          title="The SparkPoint Token"
          subtitle={`The SRK Token powers the SRK Ecosystem and its connected platforms. It is an ERC-20 token deployed on the Ethereum Blockchain with a Binance Smart Chain (SRKb BEP-20) version that can be utilized for staking on SparkPoint's Decentralized Finance Platform, SparkSwap. It has a current total supply of ${supply} ${units} tokens which will be reduced to 10 billion through a series of token burning events. SRK is one of the few cryptocurrency projects from the Philippines that are actively traded on exchanges.`}
        />
        <div className="token-hero-image-wrapper d-flex justify-content-center align-items-center">
          <Fade>
            <div className="srk-token-image">
              <img src={srkToken} alt="SparkPoint Token" />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Hero
