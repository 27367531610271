import { Fade } from 'react-reveal'
import './style.scss'

const Section = (props) => {
  const { title, subtitle, className } = props

  return (
    <div className={`section ${className}`}>
      <Fade>
        <h1 className="text-center text-custom-blue">{title}</h1>
      </Fade>
      {subtitle && (
        <Fade>
          <div className="d-flex justify-content-center align-items-center">
            <p className="text-center text-custom-gray my-5 my-md-2 fs-5">{subtitle}</p>
          </div>
        </Fade>
      )}
    </div>
  )
}

export default Section
