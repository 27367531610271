import { useEffect } from 'react'
import { FAQComponent } from 'components/pages/FAQ'

const FAQ = () => {
  useEffect(() => {
    document.title = `SparkPoint | FAQs`
  }, [])
  
  return (
    <>
      <FAQComponent />
    </>
  )
}

export default FAQ