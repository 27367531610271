import { useEffect } from 'react'
import {
  Download,
  Features,
  Hero,
  Powered,
  Telegram
} from 'components/pages/Wallet'
import { scroller } from 'react-scroll'

const Wallet = () => {
  const checkPath = () => {
    const location = window.location.href
    const hasId = location.split('#').length > 2
    if(hasId) {
      let name;
      switch(location.split('#')[2]) {
        case 'overview':
          name = 'wallet-features';
          break;
        case 'features':
          name = 'wallet-upcoming-features';
          break;
        case 'download':
          name = 'wallet-download';
          break;
        default:
          name = '';
          break;
      }
      setTimeout(() => {
        scroller.scrollTo(name, {
          duration: 300,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -50
        })
      }, 1000);
    }
  }
  useEffect(() => {
    document.title = `SparkPoint | Wallet`
    document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', 'wallet.png')
    document.querySelectorAll('meta[property=twitter\\:image]')[0].setAttribute('content', 'wallet.png')
    checkPath()
  })
  return (
    <>
      <Hero />
      <Features />
      <Powered />
      { /*<UpcomingFeatures /> */ }
      <Download />
      <Telegram />
    </>
  )
}

export default Wallet
