import './style.scss'

const NotFound = () => {
  return (
    <div
      id="not-found"
      className="text-center d-flex justify-content-center align-items-center"
    >
      <h1>Page not found</h1>
    </div>
  )
}

export default NotFound
