import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faDiscord,
  faYoutube,
  faMedium,
  faReddit,
  faTelegram,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'
import './style.scss'

const Socials = (props) => {
  const { className } = props

  return (
    <div id="socials">
      <div
        className={`${className} text-custom-orange d-flex flex-wrap justify-content-center align-items-center gap-3`}
      >
        <a
          href="https://twitter.com/sparkpointio"
          target="_blank"
          rel="noreferrer"
          className="socials-link"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a
          href="https://facebook.com/sparkpointio"
          target="_blank"
          rel="noreferrer"
          className="socials-link"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://instagram.com/sparkpointio"
          target="_blank"
          rel="noreferrer"
          className="socials-link"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://discord.gg/VPDh6Hq8JQ"
          target="_blank"
          rel="noreferrer"
          className="socials-link"
        >
          <FontAwesomeIcon icon={faDiscord} />
        </a>
        <a
          href="https://www.youtube.com/c/sparkpointio"
          target="_blank"
          rel="noreferrer"
          className="socials-link"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a
          href="https://medium.com/theecosystem"
          target="_blank"
          rel="noreferrer"
          className="socials-link"
        >
          <FontAwesomeIcon icon={faMedium} />
        </a>
        <a
          href="https://www.reddit.com/r/SparkPoint/"
          target="_blank"
          rel="noreferrer"
          className="socials-link"
        >
          <FontAwesomeIcon icon={faReddit} />
        </a>
        <a
          href="https://t.me/SparkpointOfficial"
          target="_blank"
          rel="noreferrer"
          className="socials-link"
        >
          <FontAwesomeIcon icon={faTelegram} />
        </a>
        <a
          href="https://www.linkedin.com/company/sparkpointio"
          target="_blank"
          rel="noreferrer"
          className="socials-link"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
    </div>
  )
}

export default Socials
