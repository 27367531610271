import { Section } from 'components/custom/Elements'
import SparkPlay from 'assets/sparkpoint/ecosystem/SparkPlay.png'
import './style.scss'

const Hero = () => {
  return (
    <div id="sparkplay-hero" className="hero-section">
      <div className="sparkplay-hero-wrapper">
        <div className="sparkplay-hero-image-wrapper">
          <img src={SparkPlay} alt="" />
        </div>
        <Section title="SparkPlay" subtitle="SparkPlay is an innovative gaming platform with a collection of crypto-themed mobile hyper casual games. Currently available games include Crypto Slicer, Crypto Burst, Crypto Shooter, and Crypto Loco." />
      </div>
    </div>
  )
}

export default Hero
