import { useState, useEffect } from 'react'
import { Section } from 'components/custom/Elements'
import { Button } from 'components/custom/Elements'
import sparkdefiLight from 'assets/sparkpoint/products/sparkdefi-light.png'
import sparkdefiDark from 'assets/sparkpoint/products/sparkdefi.png'
import wallet from 'assets/sparkpoint/products/sparkpointwallet.png';
import networkImg from 'assets/sparkpoint/products/simpler.webp';
import { Element } from 'react-scroll'
import './style.scss'
import { useStoreState } from 'easy-peasy'
import { Fade } from 'react-reveal'

const Products = () => {
  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [sparkdefi, setSparkdefi] = useState(
    currentTheme === 'light' ? sparkdefiLight : sparkdefiDark
  )

  useEffect(() => {
    setSparkdefi(currentTheme === 'light' ? sparkdefiLight : sparkdefiDark)
  }, [currentTheme])

  return (
    <div id="home-products" name="home-products">
      <div className="main-container">
      <Section title="Our Products" />
      <div className="token-ecosystem-wrapper d-flex gap-5 py-3">
        <div className="token-ecosystem-item">
          <div className="token-ecosystem-item-wrapper d-flex justify-content-center gap-5">
            <Element
              name="sparkdefi"
              className="sparkdefi token-ecosystem-item-info shadow rounded lh-lg d-flex flex-column justify-content-between"
            >
              <Fade>
                <div className="token-section-wrapper">
                <div className="token-section-text">
                  <b className="text-custom-orange">DECENTRALIZED FINANCE</b>
                  <h1 className="text-custom-blue fw-bold">SparkSwap</h1>
                  <p className="py-3">
                  SparkSwap enables you to unlock the full potential of your crypto assets. Trade seamlessly with the SparkSwap DEX, manage futures with SparkSwap Futures, and earn passive income through SparkPool's farms and staking. Efficiently distribute tokens with the Multisender App, bridge assets across blockchains with SparkBridge, and explore the world of NFTs with SparkNFT (minting, buying, selling, farming coming soon). Discover innovative projects through SparkLaunch, all within the secure SparkSwap App.
                  </p>
                  <a href="https://sparkswap.finance/" target="_blank" rel="noreferrer">
                    <Button
                      value="Go to SparkSwap"
                      variant="fill"
                      className="align-self-start"
                    />
                  </a>
                </div>
                <div className="image text-end mt-5">
                  <img src={sparkdefi} alt="SparkDeFi" />
                </div>
                </div>
              </Fade>
            </Element>
          </div>
        </div>
      </div>
      <div className="token-ecosystem-wrapper d-flex gap-5 py-3">
        <div className="token-ecosystem-item">
          <div className="token-ecosystem-item-wrapper d-flex justify-content-center gap-5">
            <Element
              name="services"
              className="sparkdefi token-ecosystem-item-info shadow rounded lh-lg d-flex flex-column justify-content-between"
            >
              <Fade>
                <div className="token-section-wrapper">
                <div className="token-section-text">
                  <b className="text-custom-orange">CRYPTO FINANCE</b>
                  <h1 className="text-custom-blue fw-bold">SparkPoint Wallet</h1>
                  <p className="py-3">
                  SparkPoint Wallet empowers you to navigate the crypto world with ease. Store and manage a wide range of popular tokens, including SRK, SFUEL, and major cryptocurrencies like Bitcoin (BTC), Ethereum (ETH), and Binance Coin (BNB). Swap tokens seamlessly within the app thanks to a built-in DEX powered by ChangeNOW and Changelly. Our latest update even lets you view and manage your NFT collection effortlessly. Take control of your crypto assets with SparkPoint Wallet's secure, non-custodial environment. Update now and unlock a world of possibilities.
                  </p>
                  <a href="https://www.sparkpoint.io/#/wallet/" target="_blank" rel="noreferrer">
                    <Button
                      value="Explore the App"
                      variant="fill"
                      className="align-self-start"
                    />
                  </a>
                </div>
                <div className="image text-end mt-5">
                  <img src={wallet} alt="SparkPoint Wallet" />
                </div>
                </div>
              </Fade>
            </Element>
          </div>
        </div>
      </div>
      <div className="token-ecosystem-wrapper d-flex gap-5 py-3">
        <div className="token-ecosystem-item">
          <div className="token-ecosystem-item-wrapper d-flex justify-content-center gap-5">
            <Element
              name="services"
              className="sparkdefi token-ecosystem-item-info shadow rounded lh-lg d-flex flex-column justify-content-between"
            >
              <Fade>
                <div className="token-section-wrapper">
                <div className="token-section-text">
                  <b className="text-custom-orange">BLOCKCHAIN</b>
                  <h1 className="text-custom-blue fw-bold">SparkPoint Network</h1>
                  <p className="py-3">
                  SparkPoint Network is a next-generation layer 3 blockchain specifically designed for the blockchain gaming industry. Built for speed, scalability, and security, SparkPoint empowers developers to create immersive and engaging gaming experiences.
                  </p>
                  <p>
                  <strong>Own a piece of the future:</strong> Participate in the network and earn rewards by purchasing SparkPoint Nodes. (Pre-sale Coming Soon!)
                  </p>
                </div>
                <div className="image text-end mt-5">
                  <img src={networkImg} alt="SparkPoint Network" />
                </div>
                </div>
              </Fade>
            </Element>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Products
