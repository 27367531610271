import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleHalfStroke } from '@fortawesome/free-solid-svg-icons'
import './style.scss'
import { useStoreActions } from 'easy-peasy'

const Theme = () => {
  const setTheme = useStoreActions((actions) => actions.theme.setTheme)

  const toggle = () => {
    if (document.documentElement.getAttribute('data-theme') === 'light') {
      document.documentElement.setAttribute('data-theme', 'dark')
      setTheme('dark')
    } else {
      document.documentElement.setAttribute('data-theme', 'light')
      setTheme('light')
    }
  }

  return (
    <div id="theme" className="position-fixed bottom-0 fs-2" onClick={toggle}>
      <FontAwesomeIcon icon={faCircleHalfStroke} />
    </div>
  )
}

export default Theme
