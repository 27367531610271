import { Button, Section } from 'components/custom/Elements'
import inbox from 'assets/wallet/telegram/3inbox.png'
import './style.scss'
import { Fade } from 'react-reveal'

const Telegram = () => {
  return (
    <div id="wallet-telegram" className="component">
      <div className="wallet-telegram-wrapper">
        <Fade>
          <div className="d-flex justify-content-center">
            <img src={inbox} alt="Inbox" />
          </div>
        </Fade>
        <Section
          title="Interested in listing your token in our Wallet App?"
          subtitle="Drop a message on Sparky's Telegram and let's discuss, shall we?"
        />
        <div className="d-flex justify-content-center align-items-center">
          <Fade>
            <a href="https://t.me/Sparky_SRK" target="_blank" rel="noreferrer">
              <Button value="Go to Telegram" variant="fill" />
            </a>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Telegram
