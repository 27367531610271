import { Section } from 'components/custom/Elements'
import usesrknow3 from 'assets/token/use-srk-now/usesrknow3.png'
import usesrknow4 from 'assets/token/use-srk-now/usesrknow4.png'
import usesrknow5 from 'assets/token/use-srk-now/usesrknow5.png'
import usesrknow6 from 'assets/token/use-srk-now/usesrknow6.png'
import './style.scss'
import { Fade } from 'react-reveal'

const UseSRK = () => {
  return (
    <div id="token-use-srk">
      <Section title="Use SRK Now" />
      <div className="token-use-srk-wrapper">
        <div className="token-use-srk d-flex flex-wrap justify-content-center align-items-center gap-4">
          <Fade>
            <div className="d-flex flex-wrap gap-4 justify-content-center align-items-center">
              <a
                href="https://medium.com/theecosystem/providing-liquidity-in-sparkswap-9f51ddb74bb7"
                target="_blank"
                rel="noreferrer"
                className="token-use-srk-item"
              >
                <img src={usesrknow4} alt="Use SRK Now" />
              </a>
              <a
                href="https://medium.com/theecosystem/how-to-bridge-tokens-in-the-new-sparkbridge-interface-58cd0d152241"
                target="_blank"
                rel="noreferrer"
                className="token-use-srk-item"
              >
                <img src={usesrknow5} alt="Use SRK Now" />
              </a>
            </div>
            <div className="d-flex flex-wrap gap-4 justify-content-center align-items-center">
              <a
                href="https://medium.com/theecosystem/sparkdefi-ataglance-sparklaunch-will-go-live-this-july-25-140532e2d1e"
                target="_blank"
                rel="noreferrer"
                className="token-use-srk-item"
              >
                <img src={usesrknow3} alt="Use SRK Now" />
              </a>
              <a
                href="https://medium.com/theecosystem/introducing-sparky-vip-badge-nft-3fe0177bbb8b"
                target="_blank"
                rel="noreferrer"
                className="token-use-srk-item"
              >
                <img src={usesrknow6} alt="Use SRK Now" />
              </a>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default UseSRK
