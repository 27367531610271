import { Section } from 'components/custom/Elements'
import Socials from 'components/custom/Socials'
import { Fade } from 'react-reveal'
import './style.scss'

const Links = () => {
  return (
    <div id="token-links" className="component">
      <Section title="Links" />
      <Fade>
        <Socials className="socials gap-5" />
      </Fade>
    </div>
  )
}

export default Links
