import { Fade } from 'react-reveal'
import './style.scss'

const Hero = () => {
  return (
    <div id="about-us-hero">
      <div
        id="hero-bg"
        className="text-center d-flex flex-column justify-content-center align-items-center"
      >
        <div className="main-container">
        <Fade>
          <div className="about-us-hero-content">
            <h1 className="title">
              REINVENTING DIGITAL PAYMENTS, BUILDING A BETTER FUTURE
            </h1>
            <h1 className="text-white">
              <i>
                "We believe that blockchain technology is a catalyst in digital
                revolution."
              </i>
            </h1>
            <div className="divider rounded my-5"></div>
            <p className="lh-lg">
              One step at a time and with the support of the community, SparkPoint
              Technologies will help build a better future for the people in terms
              of mobile payments, remittances, exchanges, gaming, etc.
            </p>
          </div>
        </Fade>
        </div>
      </div>
    </div>
  )
}

export default Hero
