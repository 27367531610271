import { useEffect } from 'react'
import {
  Hero,
  Partners,
} from 'components/pages/Partnerships'
import { scroller } from 'react-scroll'

import { Contact } from 'components/pages/Home'

const Partnerships = () => {
  const checkPath = () => {
    const location = window.location.href
    const hasId = location.split('#').length > 2
    if(hasId && location.split('#')[2] === 'businessform') {
      setTimeout(() => {
        scroller.scrollTo('home-contact', {
          duration: 300,
          delay: 0,
          smooth: 'easeInOutQuart'
        })
      }, 1000);
    }
  }

  useEffect(() => {
    document.title = `SparkPoint | Partnerships`
    document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', 'partnerships.png')
    document.querySelectorAll('meta[property=twitter\\:image]')[0].setAttribute('content', 'partnerships.png')
    checkPath()
  })
  return (
    <>
      <Hero />
      <Partners />
      <Contact />
    </>
  )
}

export default Partnerships
