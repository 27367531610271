import { Section } from 'components/custom/Elements'
import './style.scss'

const Hero = () => {
  return (
    <div id="roadmap-hero" className="hero-section lh-lg">
      <div className="main-container">
        <Section
          title="SparkPoint Roadmap"
          subtitle="Founded in 2018, SparkPoint Technologies Inc. is a startup blockchain company with a global vision. We are a passionate team of innovators dedicated to unlocking the transformative power of blockchain technology with a mission to accelerate the mainstream adoption of blockchain by developing user-friendly products, services, and solutions that empower individuals and businesses to leverage the potential of this revolutionary technology."
        />
        <div className="section roadmap-header-text">
          <p className="text-center fs-5 pb-5">
            We strive to bridge the gap between traditional and decentralized technologies, fostering a more inclusive and transparent digital future for the Philippines and the world.
          </p>
          <p className="text-center fs-5 pb-5">
            From its launch in 2018, SparkPoint has achieved the following milestones:
          </p>
        </div>
      </div>
    </div>
  )
}

export default Hero
