import { Section } from 'components/custom/Elements'
import coingecko from 'assets/token/more-info/coingecko-logo-vector.png'
import etherscan from 'assets/token/more-info/logo-etherscan.png'
import bscscan from 'assets/token/more-info/bscscan-logo.png'
import coinmarketcap from 'assets/token/more-info/CoinMarketCap.png'
import './style.scss'
import { Fade } from 'react-reveal'

const MoreInfo = () => {
  return (
    <div id="token-more-info" className="component">
      <Section title="More Info" />
      <div className="d-flex flex-wrap justify-content-center align-items-stretch gap-3">
        <Fade>
          <a
            href="https://www.coingecko.com/en/coins/sparkpoint"
            target="_blank"
            rel="noreferrer"
            className="shadow more-info-image-wrapper d-flex justify-content-center align-items-center"
          >
            <img src={coingecko} alt="CoinGecko" />
          </a>
          <a
            href="https://etherscan.io/token/0x0488401c3f535193fa8df029d9ffe615a06e74e6"
            target="_blank"
            rel="noreferrer"
            className="shadow more-info-image-wrapper d-flex justify-content-center align-items-center"
          >
            <img src={etherscan} alt="Etherscan" />
          </a>
          <a
            href="https://bscscan.com/token/0xc3440c10c4f36f354eb591b19fafb4906d449b75"
            target="_blank"
            rel="noreferrer"
            className="shadow more-info-image-wrapper d-flex justify-content-center align-items-center"
          >
            <img src={bscscan} alt="BSC Scan" />
          </a>
          <a
            href="https://coinmarketcap.com/currencies/sparkpoint/"
            target="_blank"
            rel="noreferrer"
            className="shadow more-info-image-wrapper d-flex justify-content-center align-items-center"
          >
            <img src={coinmarketcap} alt="CoinMarketCap" />
          </a>
        </Fade>
      </div>
    </div>
  )
}

export default MoreInfo
