import ethLogo from 'assets/home/token/eth.png'
import bscLogo from 'assets/home/token/bsc.png'
import './style.scss'
import { Fade } from 'react-reveal'
import { useStoreState } from 'easy-peasy'
import metamask from 'assets/metmask.png'
import Web3 from 'web3'
import Web3Eth from 'web3-eth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer, toast } from 'react-toastify'

let web3obj
const web3 = window.web3

const Info = () => {

  const supply = useStoreState((state) => state.supply.amount)
    .toString()
    .slice(0, 4)
  const units = useStoreState((state) => state.supply.units)

  const addToWalletERC = () => {
    if (typeof ethereum !== 'undefined') {
      web3obj = new Web3(web3.currentProvider);
    } else {
      alert('No web3 provider');
      return
    }

    let network = 0
    web3obj.eth.net.getId((err, netId) => {
      network = netId.toString()

      switch (network) {
        case "1":
          network = 'mainnet';
          break;
        default:
          console.log('unknown network');
      }

      if (network === 'mainnet') {
        try {
          web3obj.eth.currentProvider.sendAsync({
            method: 'wallet_watchAsset',
            params: {
              'type': "ERC20",
              'options': {
                'address': "0x0488401c3f535193fa8df029d9ffe615a06e74e6",
                'symbol': "SRK",
                'decimals': 18,
                'image': 'http://bscscan.com/token/images/sparkpoint_32.png'
              },
            },
            id: Math.round(Math.random() * 100000)
          }, function(err, data) {
            if (!err) {
              data.result ? console.log('Token added') : console.log('error')
            } else {
              console.log(err.message)
            }
          })
        } catch (error) {
          console.error(error)
        }
      } else {
        alert("Please connect to mainnet network")
        return false
      }
    })
  }

  const addToWalletBEP = () => {
    if (typeof ethereum !== 'undefined') {
      web3obj = new Web3Eth(web3.currentProvider);
    } else {
      alert('No web3 provider');
      return
    }

    let network = 0
    web3obj.net.getId((err, netId) => {
      network = netId.toString()

      switch (network) {
        case "56":
          network = 'bsc'
          break
        default:
          console.log('unknown network')
      }

      if (network === 'bsc') {
        try {
          web3obj.currentProvider.sendAsync({
            method: 'wallet_watchAsset',
            params: {
              'type': "ERC20",
              'options': {
                'address': "0xc3440c10c4f36f354eb591b19fafb4906d449b75",
                'symbol': "SRKb",
                'decimals': 18,
                'image': 'http://bscscan.com/token/images/sparkpoint_32.png'
              },
            },
            id: Math.round(Math.random() * 100000)
          }, function(err, data) {
            if (!err) {
              data.result ? console.log('Token added') : console.log('error')
            } else {
              console.log(err.message)
            }
          })
        } catch (error) {
          console.error(error)
        }
      } else {
        alert("Please connect to Binance Smart Chain network")
        return false
      }
    })
  }

  const copyAddress = (address) => {
    const value = document.getElementById(address).innerHTML
    if (navigator.clipboard) {
      toast.success('Copied!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
      navigator.clipboard.writeText(value).then(() => {
        console.log("Text copied to clipboard.")
      })
      return
    }
    alert('Clipboard API not supported')
  }

  return (
    <div id="token-info" className="component">
      <ToastContainer />
      <div className="token-supply d-flex flex-wrap justify-content-center gap-5">
        <Fade>
          <div className="token-supply-wrapper d-flex flex-column justify-content-between">
            <div className="ticker-wrapper">
              <p className="fw-bold">Ticker Name</p>
              <h1 className="text-custom-blue fw-bold">SRK</h1>
            </div>
            <div className="rank-wrapper">
              <p className="fw-bold">Highest rank achieved on CoinMarketCap</p>
              <h1 className="text-custom-blue fw-bold">No. 247</h1>
              <p className="text-custom-gray">
                Out of 18,000+ cryptocurrencies
              </p>
            </div>
          </div>
          <div className="token-supply-wrapper d-flex flex-column justify-content-between">
            <div className="total-supply-wrapper">
              <p className="fw-bold">Current Total Supply</p>
              <h1 className="text-custom-blue fw-bold">{supply} {units}</h1>
              <p className="text-custom-gray">
                To be reduced to 10 billion tokens through a series of <br />{' '}
                burn events
              </p>
            </div>
            <div className="burned-wrapper">
              <p className="fw-bold">Burned to date</p>
              <h1 className="text-custom-blue fw-bold">6.5 billion</h1>
              <p className="text-custom-gray">
                From the original 20 billion supply
              </p>
            </div>
          </div>
        </Fade>
      </div>
      <div className="chain-wrapper d-flex justify-content-center align-items-center text-center gap-5">
        <Fade>
          <div className="ethereum-address-wrapper">
            <div className="ethereum-address">
              <div className="eth d-flex justify-content-center align-items-center gap-3">
                <img src={ethLogo} alt="Ethereum logo" />
                <p>Ethereum &#40;ERC-20&#41;</p>
              </div>
              <p className="text-custom-gray">
                Contract Address
                <span onClick={() => copyAddress('erc20')} className="icon px-2">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
              </p>
              <p className="text-custom-blue fs-4 fw-bold" id="erc20">
                0x0488401c3f535193fa8df029d9ffe615a06e74e6
              </p>
              <button className='button' onClick={addToWalletERC}>Add to <img src={metamask} alt="MetaMask" /> MetaMask Wallet</button>
            </div>
          </div>
          <div className="binance-address-wrapper">
            <div className="binance-address">
              <div className="bsc d-flex justify-content-center align-items-center gap-3">
                <img src={bscLogo} alt="Binance Smart Chain logo" />
                <p>Binance &#40;BEP-20&#41;</p>
              </div>
              <p className="text-custom-gray">
                Contract Address
                <span onClick={() => copyAddress('bep20')} className="icon px-2">
                  <FontAwesomeIcon icon={faCopy} />
                </span>
              </p>
              <p className="text-custom-blue fs-4 fw-bold" id="bep20">
                0xc3440c10c4f36f354eb591b19fafb4906d449b75
              </p>
              <button className='button' onClick={addToWalletBEP}>Add to <img src={metamask} alt="MetaMask" /> MetaMask Wallet</button>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Info
