import { useEffect } from 'react'
import {
  Hero,
  Team,
} from 'components/pages/AboutUs'
import { scroller } from 'react-scroll'

const AboutUs = () => {
  const checkPath = () => {
    const location = window.location.href
    const hasId = location.split('#').length > 2
    if(hasId) {
      let name;
      switch(location.split('#')[2]) {
        case 'mission-and-vision':
          name = 'about-us-mission';
          break;
        case 'team':
          name = 'about-us-team';
          break;
        case 'location':
          name = 'about-us-location';
          break;
        default:
          name = '';
          break;
      }
      setTimeout(() => {
        scroller.scrollTo(name, {
          duration: 300,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -50
        })
      }, 1000);
    }
  }
  useEffect(() => {
    document.title = `SparkPoint | About Us`
    document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', 'about.png')
    document.querySelectorAll('meta[property=twitter\\:image]')[0].setAttribute('content', 'about.png')
    checkPath()
  })
  return (
    <div id="about-us-root">
      <Hero />
      <Team />
      {/* <Location /> */}
    </div>
  )
}

export default AboutUs
