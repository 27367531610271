import React, { useState, useRef } from 'react';
import {
  faChevronCircleRight,
  faChevronCircleLeft
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Twentytwentyfour, Twentytwentyfourvertical } from '../components';

import roadmapLineImg from 'assets/roadmap/roadmap-line.webp';

import './style.scss'


const SlideOne = () => {
  return (
    <div className="slide-container">
      <div className="year-container">
        <div className="roadmap-container">
          <div className="year-header">
            2018
          </div>
          <div className='roadmap-timeline'>
            <div className="top-text">
              <div>&nbsp;</div>
            </div>
            <img src={roadmapLineImg} className="roadmap-line" alt="roadmap" />
            <div className="bottom-text">
              <div>Official Project Launch of SparkPoint</div>
            </div>
          </div>
          <div className="year-header d-block d-lg-none">
            2019
          </div>
        </div>
      </div>
    </div>
  );
}

const SlideTwo = () => {
  return (
    <div className="slide-container">
      <div className="year-container">
        <div className="roadmap-container">
          <div className="year-header">
            2019
          </div>
          <div className='roadmap-timeline'>
            <div className="top-text">
              <div>SparkX Wallet App</div>
              <div>SRK Private Sale</div>
              <div>SRK (ERC-20) Token Deployment</div>
            </div>
            <img src={roadmapLineImg} className="roadmap-line" alt="roadmap" />
            <div className="bottom-text">
              <div>&nbsp;</div>
            </div>
          </div>
          <div className="year-header">
            2020
          </div>
        </div>
      </div>
    </div>
  );
}

const SlideThree = () => {
  return (
    <div className="slide-container">
      <div className="year-container">
        <div className="roadmap-container">
          <div className="year-header">
            2020
          </div>
          <div className='roadmap-timeline'>
            <div className="top-text">
              <div>&nbsp;</div>
            </div>
            <img src={roadmapLineImg} className="roadmap-line" alt="roadmap" />
            <div className="bottom-text">
              <div>Crypto Slicer Game Release</div>
              <div>SFUEL Private Sale</div>
              <div>SFUEL (BEP-20) Token Deployment</div>
              <div>SparkDeFi Platform Launch</div>
              <div>SparkEarn Bounty Program</div>
              <div>SparkLearn Platform</div>
              <div>Crypto Burst Game Release</div>
              <div>Crypto Shooter Game Release</div>
              <div>New version of SparkPoint Wallet </div>
            </div>
          </div>
          <div className="year-header d-block d-lg-none">
            2021
          </div>
        </div>
      </div>
    </div>
  );
}

const SlideFour = () => {
  return (
    <div className="slide-container">
      <div className="year-container">
        <div className="roadmap-container">
          <div className="year-header">
            2021
          </div>
          <div className='roadmap-timeline'>
            <div className="top-text">
              <div>SRKb (BEP-20) Token Deployment</div>
              <div>First Blockchain Developers Bootcamp by SparkLearn</div>
              <div>SparkLaunch Launchpad</div>
              <div>SparkSwap Official Launch</div>
              <div>
                Crypto Loco Game Release
              </div>
              <div>
                Sparky VIP Club
              </div>
            </div>
            <img src={roadmapLineImg} className="roadmap-line" alt="roadmap" />
            <div className="bottom-text">
              <div>&nbsp;</div>
            </div>
          </div>
          <div className="year-header">
            2022
          </div>
        </div>
      </div>
    </div>
  );
}

const SlideFive = () => {
  return (
    <div className="slide-container">
      <div className="year-container">
        <div className="roadmap-container">
          <div className="year-header">
            2022
          </div>
          <div className='roadmap-timeline'>
            <div className="top-text">
              <div>&nbsp;</div>
            </div>
            <img src={roadmapLineImg} className="roadmap-line" alt="roadmap" />
            <div className="bottom-text">
              <div>SparkSwap v2 Launch</div>
              <div>Sparky NFT Collection Launch</div>
              <div>Sparky VIP Badge NFT</div>
              <div>Ecosystem Development</div>
            </div>
          </div>
          <div className="year-header d-block d-lg-none">
            2023
          </div>
        </div>
      </div>
    </div>
  );
}

const SlideSix = () => {
  return (
    <div className="slide-container">
      <div className="year-container">
        <div className="roadmap-container">
          <div className="year-header">
            2023
          </div>
          <div className='roadmap-timeline'>
            <div className="top-text">
              <div>SparkSwap Multisender App Launch</div>
              <div>SparkSwap Futures Launch</div>
              <div>SparkTech.Dev Launch</div>
            </div>
            <img src={roadmapLineImg} className="roadmap-line" alt="roadmap" />
            <div className="bottom-text">
              <div>&nbsp;</div>
            </div>
          </div>
          <div className="year-header">
            2024
          </div>
        </div>
      </div>
    </div>
  );
}

const SlideSeven = () => {
  return (
    <div className="last-slide-container">
      <div id="roadmap-years">
        <div className="horizontal component">
          <Twentytwentyfour />
        </div>
        <div className="vertical">
            <Twentytwentyfourvertical />
          </div>
      </div>
    </div>
  );
}

const SlideFiller = () => {
  return (
    <div>&nbsp;</div>
  );
}

const responsive = {
  0: { items: 1 },
  1024: { items: 2 },
};

const Carousel = () => {
  const handleDragStart = (e) => e.preventDefault();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const isMobile = windowSize.current[0] < 1024;
  const scrollSize = isMobile ? 1 : 2;

  const items = [
    <SlideOne onDragStart={handleDragStart} />,
    <SlideTwo onDragStart={handleDragStart} />,
    <SlideThree onDragStart={handleDragStart} />,
    <SlideFour  onDragStart={handleDragStart} />,
    <SlideFive onDragStart={handleDragStart} />,
    <SlideSix onDragStart={handleDragStart} />,
    <SlideSeven onDragStart={handleDragStart} />,
  ];

  if (!isMobile) {
    items.push(<SlideFiller />);
  }

  const [activeIndex, setActiveIndex] = useState(items.length - scrollSize);

  const slidePrev = () => {
    let prevIndex = activeIndex - scrollSize;
    if (prevIndex < 0) {
      prevIndex = items.length + prevIndex;
    }
    setActiveIndex(prevIndex);
  };
  const slideNext = () => {
    let nextIndex = activeIndex + scrollSize;
    if (nextIndex > items.length - 1) {
      nextIndex = (nextIndex % items.length);
    }
    setActiveIndex(nextIndex);
  };

  const syncActiveIndexForSwipeGestures = (e) => setActiveIndex(e.item);

  const onSlideChanged = (e) => {
    syncActiveIndexForSwipeGestures(e);
  };

  return (
    <div id="roadmap-carousel" className="hero-section lh-lg">
      <div className="carousel-container">
        <div onClick={slidePrev} className="carousel-buttons">
          <FontAwesomeIcon icon={faChevronCircleLeft} />
        </div>
        <AliceCarousel
          autoHeight
          items={items}
          infinite
          disableDotsControls
          disableButtonsControls
          onSlideChanged={onSlideChanged}
          activeIndex={activeIndex}
          animationType="slide"
          responsive={responsive}
        />
        <div onClick={slideNext} className="carousel-buttons">
          <FontAwesomeIcon icon={faChevronCircleRight} />
        </div>
      </div>
    </div>
  )
}

export default Carousel
