
import {
  Twentytwentyfour,
  Twentytwentyfourvertical
} from '../components'
import { Section } from 'components/custom/Elements'
import './style.scss'

const Roadmapyears = () => {
  return (
    <div id="roadmap-years">
      <div className="main-container">
        <div className="component">
          <Section
              title="2018"
              subtitle="SparkPoint initiated its journey by establishing its core team, conducting research on blockchain technology, and laying the groundwork for its projects, including the creation of smart contracts and the publication of a white paper."
            />
          <Section
            title="2019"
            subtitle="2019 saw significant expansion with cryptocurrency exchange listings, regulatory registrations, and the launch of the SparkPoint Cryptocurrency Wallet."
          />
          <Section
            title="2020"
            subtitle="The year 2020 marked substantial progress with the introduction of SparkPlay games, revenue generation from gaming, and the launch of SparkDeFi DApps, alongside continued team expansion and product enhancements."
          />
          <Section
            title="2021"
            subtitle="In 2021, the company achieved listings on top-tier exchanges, released major updates for its SparkPoint Wallet, and introduced several SparkDeFi DApps. The year also saw ongoing partnerships, the launch of SparkLearn Blockchain Developer Bootcamp, and further team expansion."
          />
          <Section
            title="2022"
            subtitle="In 2022, notable milestones included the graduation of the first cohort from the SparkLearn Bootcamp, the launch of new features and updates for the SparkPoint ecosystem, and ongoing efforts towards obtaining a Virtual Currency Exchange License. "
          />
          <Section
            title="2023"
            subtitle="In 2023, the company continued to innovate with enhancements to SparkSwap, SparkPoint Wallet, and SparkTech Development Services, alongside numerous community partnerships and engagement initiatives."
          />
          <div className="horizontal component">
            <Twentytwentyfour />
          </div>
          <div className="vertical">
            <Twentytwentyfourvertical />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Roadmapyears
