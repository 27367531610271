import { useEffect, useState } from 'react'
import { Section, Button } from 'components/custom/Elements'
import rocket from 'assets/home/updates/rocket.png'
import './style.scss'
import { Fade } from 'react-reveal'

const axios = require('axios')
const regex = /(https?:\/\/[^ ]*)/

const Updates = () => {
  const [feed, setFeed] = useState({
    featured: [],
    all: []
  })

  const convertedDate = (date) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]

    const month = months[new Date(date).getUTCMonth()]
    const day = new Date(date).getUTCDate()
    const year = new Date(date).getUTCFullYear()

    if (isNaN(new Date(date).getUTCDate())) {
      return
    }

    return `${month} ${day}, ${year}`
  }

  const newsFeed = async () => {
    try {
      const response = await axios.get(
        'https://test-api-cyan.vercel.app/api/v1/rss'
      )
      setFeed({
        featured: [response.data.items[0]],
        all: response.data.items.slice(1, 5)
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    newsFeed()
  }, [])

  return (
    <div id="home-updates" name="home-updates" className="component">
      <div className="main-container">
        <Section title="Project Updates" />
        {/* FEATURED */}
        <div className="home-updates-featured">
          <div className="featured d-flex justify-content-evenly align-items-center gap-5">
            {feed.featured.map((item, i) => (
              <div key={i} className="featured-item">
                <Fade>
                  <img src={item['content:encoded'].match(regex)[0].slice(0, -1)} alt="thumbnail" />
                  <div className="updates-info d-flex flex-column gap-3 py-4">
                    <span className="text-custom-orange text-uppercase fw-bold">
                      {item.creator} | {convertedDate(item.pubDate)}
                    </span>
                    <p className="fw-bold fs-1">{item.title}</p>
                  </div>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <Button value="READ MORE" variant="fill" />
                  </a>
                </Fade>
              </div>
            ))}
            <Fade>
              <img src={rocket} alt="Rocket" id="rocket" />
            </Fade>
          </div>
        </div>
        {/* OTHERS */}
        <div className="home-updates-others d-flex flex-wrap justify-content-center gap-5 py-5">
          {feed.all.map((item, i) => (
            <div key={i} className="others-item">
              <Fade>
                <img src={item['content:encoded'].match(regex)[0].slice(0, -1)} alt="thumbnail" />
                <div className="updates-info d-flex flex-column gap-3 py-4">
                  <span className="text-custom-orange text-uppercase fw-bold">
                    {item.creator} | {convertedDate(item.pubDate)}
                  </span>
                  <p className="fw-bold fs-1">{item.title}</p>
                </div>
                <a href={item.link} target="_blank" rel="noreferrer">
                  <Button value="READ MORE" variant="fill" />
                </a>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Updates
