import NotFoundPage from 'components/pages/NotFound'
import { useEffect } from 'react'

const NotFound = () => {
  useEffect(() => {
    document.title = `SparkPoint | 404`
  })
  return (
    <>
      <NotFoundPage />
    </>
  )
}

export default NotFound
