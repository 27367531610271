import { Section } from 'components/custom/Elements'
import contactDark from 'assets/home/contact/contact.png'
import contactLight from 'assets/home/contact/contact-light.png'
import { Form, Row, Col } from 'react-bootstrap'
import { Button } from 'components/custom/Elements'
import './style.scss'
import { useEffect, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { Fade } from 'react-reveal'
import validator from 'validator'
import ModalComponent from 'components/custom/Modal'

const axios = require('axios')

const Contact = () => {
  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [contactImage, setContactImage] = useState(
    currentTheme === 'light' ? contactLight : contactDark
  )
  const [status, setStatus] = useState('')

  const [sending, setSending] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const [details, setDetails] = useState({
    name: '',
    email: '',
    company: '',
    subject: '',
    message: ''
  })

  const [showError, setShowError] = useState({
    name: '',
    email: '',
    message: ''
  })

  const [modalShow, setModalShow] = useState(false)

  const send = async () => {
    setSending(true)
    setIsDisabled(true)
    try {
      await axios.post('https://test-api-cyan.vercel.app/api/v1/send', details)
      setSending(false)
      setDetails({
        name: '',
        email: '',
        company: '',
        subject: '',
        message: ''
      })
      setModalShow(true)
      setTimeout(() => {
        setIsDisabled(false)
        setStatus('')
      }, 3000)
    } catch (error) {
      console.error(error)
      setStatus(
        <p className="text-danger">
          Failed! Too many attempts. Please try again later.
        </p>
      )
    }
  }

  const sendEmail = () => {

    details.name === '' ? setShowError(prevState => ({...prevState, name: true})) : setShowError(prevState => ({...prevState, name: false}))
    details.email === '' ? setShowError(prevState => ({...prevState, email: true})) : setShowError(prevState => ({...prevState, email: false}))
    details.message === '' ? setShowError(prevState => ({...prevState, message: true})) : setShowError(prevState => ({...prevState, message: false}))

    if (
      details.name &&
      details.email &&
      details.message !== '' &&
      validator.isEmail(details.email)
    ) {
      send()
    } else {
      setStatus(
        <p className="text-danger">
          Failed! Please check if all information above is correct.
        </p>
      )
    }
  }

  useEffect(() => {
    setContactImage(currentTheme === 'light' ? contactLight : contactDark)
  }, [currentTheme])

  return (
    <div id="partnerships-contact" name="partnerships-contact">
      <div className="main-container">
        <div className="partnerships-flex d-flex flex-wrap justify-content-center align-items-center component">
          <div className="partnerships-contact-item">
            <Section
              title="Contact Us"
              subtitle="Enter the details below and we'll connect with you to answer any and all questions."
            />
            <ModalComponent title="E-mail sent!" body="Thank you for sending us your inquiry! A representative from SparkPoint Support Team will be responding to your inquiry within 24 hours." show={modalShow} onHide={() => setModalShow(false)} />
            <Fade>
              <Form>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label>
                      Name
                      <span className={`text-danger ${!showError.name ? 'd-none' : ''}`}>&nbsp;&#42;</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={showError.name ? 'input-error': ''}
                      value={details.name}
                      required
                      id="name"
                      onChange={(e) => {
                        setDetails(prevState => ({...prevState, name: e.target.value}))
                      }}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>
                      Email
                      <span className={`text-danger ${!showError.email ? 'd-none' : ''}`}>&nbsp;&#42;</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      className={showError.email ? 'input-error': ''}
                      value={details.email}
                      required
                      id="email"
                      onChange={(e) => {
                        setDetails(prevState => ({...prevState, email: e.target.value}))
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridCompany">
                    <Form.Label>
                      Company
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={details.company}
                      onChange={(e) => {
                        setDetails(prevState => ({...prevState, company: e.target.value}))
                      }}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridSubject">
                    <Form.Label>
                      Subject
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={details.subject}
                      onChange={(e) => {
                        setDetails(prevState => ({...prevState, subject: e.target.value}))
                      }}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridMessage">
                    <Form.Label>
                      Message
                      <span className={`text-danger ${!showError.message ? 'd-none' : ''}`}>&nbsp;&#42;</span>
                    </Form.Label>
                    <Form.Control
                      className={showError.message ? 'input-error': ''}
                      as="textarea"
                      rows={5}
                      value={details.message}
                      required
                      id="message"
                      onChange={(e) => {
                        setDetails(prevState => ({...prevState, message: e.target.value}))
                      }}
                    />
                  </Form.Group>
                </Row>
                <div id="status">{status}</div>
                <Button
                  value={sending ? 'SENDING...' : 'SEND'}
                  variant="fill"
                  className="w-100 mt-3"
                  onClick={sendEmail}
                  disabled={isDisabled}
                />
              </Form>
            </Fade>
          </div>
          <div className="partnerships-contact-item">
            <Fade>
              <img src={contactImage} alt="Contact" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
