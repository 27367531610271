import { Section } from 'components/custom/Elements'
import downloadAndroid from 'assets/home/hero/Carousel 1_2.png'
import downloadApple from 'assets/home/hero/Carousel 1_3.png'
import './style.scss'
import { Fade } from 'react-reveal'

const Download = () => {
  return (
    <div id="wallet-download">
      <div className="wallet-download-wrapper component">
        <Section title="Download the latest version now!" />
        <div className="d-flex flex-wrap justify-content-center align-items-center gap-5">
          <Fade>
            <a
              href="https://play.google.com/store/apps/details?id=com.sparkpoint"
              target="_blank"
              rel="noreferrer"
            >
              <img src={downloadAndroid} alt="Get it on Google Play" />
            </a>
            <a
              href="https://apps.apple.com/ph/app/sparkpoint-crypto-wallet/id1572629350"
              target="_blank"
              rel="noreferrer"
            >
              <img src={downloadApple} alt="Download on the App Store" />
            </a>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Download
