import { useState, useEffect } from 'react'
import { Button, Section } from 'components/custom/Elements'
import sparkdefiLight from 'assets/sparkpoint/products/sparkdefi-light.png'
import sparkdefiDark from 'assets/sparkpoint/products/sparkdefi.png'
import { useStoreState } from 'easy-peasy'
import { Fade } from 'react-reveal'

const Ecosystem = () => {
  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [sparkdefi, setSparkdefi] = useState(
    currentTheme === 'light' ? sparkdefiLight : sparkdefiDark
  )

  useEffect(() => {
    setSparkdefi(currentTheme === 'light' ? sparkdefiLight : sparkdefiDark)
  }, [currentTheme])

  return (
    <div id="token-ecosystem" name="token-ecosystem">
      <Section
        title="Utilities"
        subtitle="SparkPoint (SRK ERC-20 and SRKb BEP-20) token is the native utility token of the SparkPoint Ecosystem. It has various use cases. It serves as the main tool for holders to stake, trade and use as payment through third-party channels. More utilities will be enabled soon."
      />
      <div className="token-ecosystem-wrapper component d-flex flex-column gap-5">
        <div className="sparkdefi shadow d-flex flex-wrap justify-content-center align-items-center">
          <Fade>
            <div className="sparkdefi-info p-5 lh-lg">
              <h1 className="text-custom-blue fw-bold">
                Vast role and function in SparkPoint's DeFi platform, SparkSwap
              </h1>
              <ul className="py-3">
                <li>
                  Staking & reward token for SparkPool Farms{' '}
                  <i>(Liquidity Staking)</i> & Pools <i>(Pool-based Staking)</i>
                  .
                </li>
                <li>
                  Staking token required to compute for allocation on tiered
                  system of SparkLaunch Initial DEX Offering <i>(IDO)</i>{' '}
                  Launchpad Platform.
                </li>
                <li>
                  Used on MetaGaming Guild's Initial NFT Offerings <i>(INO)</i>{' '}
                  & Initial Game Offerings <i>(IGO) (coming soon)</i>.
                </li>
                <li>
                  Reward token for liquidity providers <i>(LPs)</i> in SparkSwap
                  Decentralized Exchange <i>(DEX)</i>.
                </li>
                <li>Used as payment for bridging tokens in SparkBridge.</li>
                <li>
                  Used as transaction fee for minting NFTs in SparkNFT Platform.
                </li>
              </ul>
              <a href="https://srk.finance/" target="_blank" rel="noreferrer">
                <Button value="Go to SparkSwap" variant="fill" />
              </a>
            </div>
            <div className="sparkdefi-image">
              <img src={sparkdefi} alt="SparkDeFi" />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Ecosystem
