import { PortfolioCard } from 'components'
// crypto shooter
import cryptoShooterBanner from 'assets/sparkplay/Crypto Shooter/Crypto Shooter.png'
//import cryptoShooterDownload from 'assets/sparkplay/Crypto Loco/DOWNLOAD NOW.png'
import cryptoShooterAndroid from 'assets/sparkplay/Crypto Loco/Layer 10.png'
// crypto burst
import cryptoBurstBanner from 'assets/sparkplay/Crypto Burst/Crypto Burst.png'
//import cryptoBurstDownload from 'assets/sparkplay/Crypto Burst/DOWNLOAD NOW.png'
import cryptoBurstAndroid from 'assets/sparkplay/Crypto Burst/Layer 10.png'
// crypto slicer
import cryptoSlicerBanner from 'assets/sparkplay/Crypto Slicer/Crypto Slicer.png'
//import cryptoSlicerDownload from 'assets/sparkplay/Crypto Slicer/DOWNLOAD NOW.png'
import cryptoSlicerAndroid from 'assets/sparkplay/Crypto Slicer/Layer 10.png'
// crypto loco
import cryptoLocoBanner from 'assets/sparkplay/Crypto Loco/Crypto Loco.png'
//import cryptoLocoDownload from 'assets/sparkplay/Crypto Loco/DOWNLOAD NOW.png'
import cryptoLocoIOS from 'assets/sparkplay/Crypto Loco/Layer 11.png'
import cryptoLocoAndroid from 'assets/sparkplay/Crypto Loco/Layer 10.png'
import './style.scss'

const Portfolio = () => {
  const cryptoShooter = {
    banner: cryptoShooterBanner,
    url: "https://www.youtube.com/embed/L7zpREhwwvc",
    action: [{
      url: "https://srk.sh/shooter",
      image: cryptoShooterAndroid
    }]
  }

  const cryptoBurst = {
    banner: cryptoBurstBanner,
    url: "https://www.youtube.com/embed/r4XNdn5oWw4",
    action: [{
      url: "https://srk.sh/burst",
      image: cryptoBurstAndroid
    }]
  }

  const cryptoSlicer = {
    banner: cryptoSlicerBanner,
    url: "https://www.youtube.com/embed/XGvsPEl-jgY",
    action: [{
      url: "https://srk.sh/slicer",
      image: cryptoSlicerAndroid
    }]
  }

  const cryptoLoco = {
    banner: cryptoLocoBanner,
    url: "https://www.youtube.com/embed/kp1sjxfHtPk",
    action: [{
      url: "https://srk.sh/loco",
      image: cryptoLocoAndroid
    }, {
      url: "https://apple.co/3GkrDx1",
      image: cryptoLocoIOS
    }]
  }

  return (
    <div id="portfolio">
      <div className="portfolio-wrapper">
        <PortfolioCard obj={cryptoShooter} />
        <PortfolioCard obj={cryptoBurst} />
        <PortfolioCard obj={cryptoSlicer} />
        <PortfolioCard obj={cryptoLoco} />
      </div>
    </div>
  )
}

export default Portfolio
