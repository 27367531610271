import { Modal } from 'react-bootstrap'
import { Button } from '../Elements'
import './style.scss'

const ModalComponent = (props) => {
  const { onHide, title, body, show } = props
  return (
    <Modal
      size='lg'
      aria-labelledby='modal-message'
      centered
      id="main-modal"
      show={show}
    >
      <Modal.Header className='main-modal-header'>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='main-modal-body'>
        <p>{body}</p>
      </Modal.Body>
      <Modal.Footer className='main-modal-footer'>
        <Button value="Close" variant="fill" onClick={onHide} />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalComponent