import './style.scss'

const ComingSoonPage = () => {
  return (
    <div
      id="coming-soon"
      className="text-center d-flex justify-content-center align-items-center"
    >
      <p className="text-custom-blue">Coming Soon</p>
    </div>
  )
}

export default ComingSoonPage
