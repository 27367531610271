import { Section } from 'components/custom/Elements'
import powered from 'assets/wallet/powered/powered_by.png'
import './style.scss'
import { Fade } from 'react-reveal'

const Powered = () => {
  return (
    <div id="wallet-powered">
      <div className="wallet-powered-wrapper component">
        <Fade>
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={powered}
              alt="Powered by Ethereum and BNB Chain, with Bitcoin network built in"
            />
          </div>
        </Fade>
        <Section title="Powered by Ethereum and BNB Chain, with Bitcoin network built in" />
      </div>
    </div>
  )
}

export default Powered
