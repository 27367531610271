import ComingSoonPage from 'components/pages/ComingSoonPage'
import { useEffect } from 'react'

const ComingSoon = () => {
  useEffect(() => {
    document.title = `SparkPoint | SparkPlay`
  })
  return (
    <>
      <ComingSoonPage />
    </>
  )
}

export default ComingSoon
