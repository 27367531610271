import { useState, useEffect } from 'react'
import { Section } from 'components/custom/Elements'
import wallet from 'assets/wallet/hero/wallet_dark.png'
import walletLogo from 'assets/sparkpoint/ecosystem/Wallet Logo.png'
import walletLight from 'assets/wallet/hero/wallet_light.png'
import './style.scss'
import { useStoreState } from 'easy-peasy'
import { Fade } from 'react-reveal'

const Hero = () => {
  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [walletImage, setWalletImage] = useState(
    currentTheme === 'light' ? walletLight : wallet
  )

  useEffect(() => {
    setWalletImage(currentTheme === 'light' ? walletLight : wallet)
  }, [currentTheme])

  return (
    <div id="wallet-hero" className="hero-section">
      <Fade>
        <div className="wallet-hero-image-wrapper d-flex justify-content-center">
          <img src={walletImage} alt="Wallet" />
        </div>
      </Fade>
      <Fade>
        <div className="d-flex justify-content-center align-items-center">
          <img src={walletLogo} alt="Wallet" />
        </div>
        <h2 className="text-center text-custom-blue fw-bold pt-5">
          Easy. Private. Secure.
        </h2>
      </Fade>
      <Section
        title="SparkPoint Wallet"
        subtitle="A non-custodial cryptocurrency wallet that can store SparkPoint (SRK) Token, Bitcoin, Ethereum, Binance Coin, MATIC, AVAX, and other ERC-20 and BEP-20 tokens. It has an in-app exchanges such as ChangeNOW, Changelly, Swapzone, and NFT Gallery where you can store your NFT Collections. SparkPoint Wallet offers high level of security and privacy."
      />
    </div>
  )
}

export default Hero
