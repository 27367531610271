import { Section } from 'components/custom/Elements'
import mgg from 'assets/about-us/affiliates/MGG.png'
import anito from 'assets/about-us/affiliates/anito.png'
import './style.scss'

const Affiliates = () => {
  return (
    <div id="sparkplay-affiliates">
      <Section title="Affiliates" subtitle="Explore the NFT Games by our affiliate gaming projects." />
      <div className="sparkplay-affiliates-wrapper">
        <div className="sparkplay-affiliates-card">
          <div className="sparkplay-affiliates-logo-wrapper">
            <img src={mgg} alt="Affiliate Logo" />
          </div>
          <div className="sparkplay-affiliates-company-title-wrapper">
            <h1>MetaGaming Guild</h1>
          </div>
          <div className="sparkplay-affiliates-video">
            <iframe width="100%" height="400" src="https://www.youtube.com/embed/_WlVM0vx7u0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
        <div className="sparkplay-affiliates-card">
          <div className="sparkplay-affiliates-logo-wrapper">
            <img src={anito} alt="Affiliate Logo" />
          </div>
          <div className="sparkplay-affiliates-company-title-wrapper">
            <h1>Anito Legends</h1>
          </div>
          <div className="sparkplay-affiliates-video">
            <iframe width="100%" height="400" src="https://www.youtube.com/embed/kax-A5nlG_w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Affiliates
