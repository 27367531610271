import { useEffect } from 'react'
import { Content } from 'components/pages/Privacy'

const Privacy = () => {
  useEffect(() => {
    document.title = `SparkPoint | Privacy`
  })
  return (
    <>
      <Content />
    </>
  )
}

export default Privacy
