import { useEffect, useState } from 'react'
import './style.scss'

const Loader = () => {
  const [hidden, isHidden] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      isHidden(true)
    }, 2000)
  })

  return (
    <div id="loader" className={hidden && 'hide'}>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 367.7 325.6"
          id="sparkpoint"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <polygon
                class="cls-1"
                points="122.6 0 0 108.5 122.6 108.5 122.6 0"
              />
              <polygon
                class="cls-2"
                points="122.6 108.6 0 217.1 122.6 217.1 122.6 108.6"
              />
              <polygon
                class="cls-3"
                points="122.6 217.1 0 325.6 122.6 325.6 122.6 217.1"
              />
              <polygon
                class="cls-3"
                points="122.6 325.6 245.1 217.1 122.6 217.1 122.6 325.6"
              />
              <polygon
                class="cls-4"
                points="122.6 217.1 245.1 108.6 122.6 108.6 122.6 217.1"
              />
              <polygon
                class="cls-5"
                points="122.6 108.6 245.1 0.1 122.6 0.1 122.6 108.6"
              />
              <polygon
                class="cls-6"
                points="0 217.1 122.6 108.6 0 108.6 0 217.1"
              />
              <polygon
                class="cls-7"
                points="245.1 108.6 367.7 0.1 245.1 0.1 245.1 108.6"
              />
              <polygon
                class="cls-8"
                points="245.1 217.1 367.7 108.6 245.1 108.6 245.1 217.1"
              />
              <polygon
                class="cls-9"
                points="245.1 108.6 122.6 217.1 245.1 217.1 245.1 108.6"
              />
              <polygon
                class="cls-10"
                points="245.1 217.1 122.6 325.6 245.1 325.6 245.1 217.1"
              />
              <polygon
                class="cls-11"
                points="245.1 325.6 367.7 217.1 245.1 217.1 245.1 325.6"
              />
              <polygon
                class="cls-12"
                points="367.7 108.6 245.1 217.1 367.7 217.1 367.7 108.6"
              />
              <polygon
                class="cls-13"
                points="245.1 0.1 122.6 108.6 245.1 108.6 245.1 0.1"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default Loader
