import { Section } from 'components/custom/Elements'
import { Fade } from 'react-reveal'
import './style.scss'

const Distribution = () => {
  return (
    <div id="token-distribution" name="token-distribution" className="component">
      <Section title="Token Distribution" />
      <div className="token-distribution-wrapper">
        <Fade>
          <div className="token-distribution d-flex flex-wrap justify-content-between align-items-center gap-5">
            <div className="token-distribution-item">
              <b className="text-custom-blue">Ecosystem Development Wallet</b>
              <p className="text-custom-gray">
                Used for development, staking rewards, liquidity provision, and
                marketing of SparkPoint Ecosystem.
              </p>
            </div>
            <div className="token-distribution-item">
              <b className="text-custom-blue">
                10M or less of SRK tokens unlock monthly
              </b>
            </div>
            <div className="token-distribution-item">
              <p className="text-custom-gray">Wallet Address:</p>
              <b className="text-custom-orange">
                0x866a3b23aee15b425ed0d98c864a3126b80e450c
              </b>
            </div>
          </div>
          <hr />
          <div className="token-distribution d-flex flex-wrap justify-content-between align-items-center gap-5">
            <div className="token-distribution-item">
              <b className="text-custom-blue">Founder's Wallet</b>
              <p className="text-custom-gray">
                Sent to the founder's wallet as compensation for the efforts in
                launching and managing SparkPoint
              </p>
            </div>
            <div className="token-distribution-item">
              <b className="text-custom-blue">
                10-year vesting and 10% linear distribution beginning March 1,
                2026
              </b>
            </div>
            <div className="token-distribution-item">
              <p className="text-custom-gray">Wallet Address:</p>
              <b className="text-custom-orange">
                0xc0288a1f580035b6a9cf3ebe7fff74946a7294a8
              </b>
            </div>
          </div>
          <hr />
          <div className="token-distribution d-flex flex-wrap justify-content-between align-items-center gap-5">
            <div className="token-distribution-item">
              <b className="text-custom-blue">Team Wallet</b>
              <p className="text-custom-gray">
                Allocated to current team members and the rest will be burnt for
                the next 5 quarters of 50M SRK
              </p>
            </div>
            <div className="token-distribution-item">
              <b className="text-custom-blue">
                5-year vesting and 20% linear distribution beginning March 1,
                2021
              </b>
            </div>
            <div className="token-distribution-item">
              <p className="text-custom-gray">Wallet Address:</p>
              <b className="text-custom-orange">
                0xe57ba6825d73579b11178bc3961967defac2adb5
              </b>
            </div>
          </div>
          <hr />
          <div className="token-distribution d-flex flex-wrap justify-content-between align-items-center gap-5">
            <div className="token-distribution-item">
              <b className="text-custom-blue">Reserve Wallet</b>
              <p className="text-custom-gray">
                Reserved for emergency purposes and long-term development of
                SparkPoint
              </p>
            </div>
            <div className="token-distribution-item">
              <b className="text-custom-blue">Lock-up for the next 5 years</b>
            </div>
            <div className="token-distribution-item">
              <p className="text-custom-gray">Wallet Address:</p>
              <b className="text-custom-orange">
                0x8c2287a7486755783e10501e17a76e4b3533be15
              </b>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Distribution
