import { Section } from 'components/custom/Elements'

const {
  faGlobe,
  faRocket,
  faWallet,
  faMobileButton,
  faCircleCheck
} = require('@fortawesome/free-solid-svg-icons')
const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome')

const Twentynineteenvertical = () => {
  return (
    <>
      <Section title="2019" />
      <div className="d-flex flex-column w-100 component">
        <div className="d-flex flex-column justify-content-start align-items-start roadmap-pill-wrapper">
          <div className="d-flex justify-content-center align-items-center gap-5 roadmap-pill">
            <FontAwesomeIcon icon={faGlobe} className="fs-1" />
            <span>1st Quarter</span>
          </div>
          <div className="roadmap-pill-content">
            <ul>
              <br />
              <li>Cryptocurrency Exchange listing <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <li>Securities and Exchange Commission Registration <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <li>Team Expansion <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <br />
            </ul>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-start align-items-start roadmap-pill-wrapper">
          <div className="d-flex justify-content-center align-items-center gap-5 roadmap-pill">
            <FontAwesomeIcon icon={faRocket} className="fs-1" />
            <span>2nd Quarter</span>
          </div>
          <div className="roadmap-pill-content">
            <ul>
              <br />
              <li>Airdrop and Bounty Token Distribution <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <li>Participation in more exchanges <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <li>SparkPoint Cryptocurrency Wallet Development <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <br />
            </ul>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-start align-items-start roadmap-pill-wrapper">
          <div className="d-flex justify-content-center align-items-center gap-5 roadmap-pill">
            <FontAwesomeIcon icon={faWallet} className="fs-1" />
            <span>3rd Quarter</span>
          </div>
          <div className="roadmap-pill-content">
            <ul>
              <br />
              <li>SparkPoint Cryptocurrency Wallet 1.0 Launch <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <li>
                Community Partnerships and Workshops to Promote SparkPoint <FontAwesomeIcon className="text-success" icon={faCircleCheck} />
              </li>
              <li>Team Expansion <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <br />
            </ul>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-start align-items-start roadmap-pill-wrapper">
          <div className="d-flex justify-content-center align-items-center gap-5 roadmap-pill">
            <FontAwesomeIcon icon={faMobileButton} className="fs-1" />
            <span>4th Quarter</span>
          </div>
          <div className="roadmap-pill-content">
            <ul className="ul-pop">
              <br />
              <li>Dlvery Expark Launch, Partnerships and Events <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <li>SparkPoint.io Website Full Redesign <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <li>
                SparkPoint Wallet Major Updates: Native BTC and BNB Support,
                Built-In DEX <FontAwesomeIcon className="text-success" icon={faCircleCheck} />
              </li>
              <li>
                More Community Partnerships and Workshops to Promote SparkPoint <FontAwesomeIcon className="text-success" icon={faCircleCheck} />
              </li>
              <li>SparkPlay Games Development <FontAwesomeIcon className="text-success" icon={faCircleCheck} /></li>
              <br />
            </ul>
          </div>
        </div>
      </div>
      <hr />
    </>
  )
}

export default Twentynineteenvertical
