import { Tab } from 'react-bootstrap'
import { Section } from 'components/custom/Elements'
import './style.scss'
import { Fade } from 'react-reveal'

const teamList = {
  all: [
    {
        name: "Harvey Javier",
        image: "/harvey-javier.jpg",
        position: "Chief Executive Officer",
        linkedin: "https://www.linkedin.com/in/harvz/",
        team: "Advisory and Auxiliary"
    },
    {
      name: "Mat Ivan Arquero",
      image: "mat-ivan.jpg",
      position: "Chief Technology Officer",
      linkedin: "https://www.linkedin.com/in/mat-ivan-arquero-311b15211",
      team: "The Wizards"
    },
    {
        name: "Medard Mandane",
        image: "medard-mandane.jpg",
        position: "Lead Backend Developer",
        linkedin: "https://www.linkedin.com/in/medardm",
        team: "The Wizards"
    },
    {
        name: "Aldrick Bonaobra",
        image: "aldrick-bonaobra.jpg",
        position: "Lead Blockchain Developer",
        linkedin: "https://www.linkedin.com/in/aldrickb",
        team: "The Wizards"
    },
    {
        name: "Christian Septimo",
        image: "christian-harris-septimo.jpg",
        position: "Lead Mobile Developer",
        linkedin: "https://www.linkedin.com/in/christianseptimo",
        team: "The Wizards"
    },
    {
      name: "Yurie Takeuchi",
      image: "yurie-takeuchi.jpg",
      position: "Lead Support",
      linkedin: "https://www.linkedin.com/in/yurie-takeuchi",
      team: "The Champions"
    },
    {
        name: "Joy Kristi Mae Balde",
        image: "joy-balde.png",
        position: "Marketing Specialist",
        linkedin: "https://www.linkedin.com/in/joy-kristi-mae-balde-58ab47104",
    },
    {
      name: "Karan Sharma",
      image: "karan-sharma.jpg",
      position: "Partnership and Outreach Manager",
      "linkedin": "https://www.linkedin.com/in/karan-sharma-41995aa0/",
      team: "Advisory and Auxiliary"
    },
    {
      name: "Tony",
      image: "tony.jpg",
      position: "Marketing Advisor",
      team: "Advisory and Auxiliary"
    },
    {
      name: "Andy Agnas",
      image: "andy-agnas.jpg",
      position: "Advisor",
      linkedin: "https://www.linkedin.com/in/andrino-agnas-2473a3158",
      team: "The Idealists",
      description: "Andy has been a financial and tax consultant of various companies for over 6 years. A CPA by profession, Andy is the managing owner of his own accounting firm and currently a director of PICPA - Cam Sur Chapter, an active and prestigious professional organization in the Philippines. Andy is also a blockchain enthusiast since 2016, being a resource speaker in various seminars relating to blockchain technology and cryptocurrencies."
    },
    {
      name: "Rico Zuñiga",
      image: "rico-zuniga.jpg",
      position: "Advisor",
      linkedin: "https://www.linkedin.com/in/ricoz",
      team: "The Idealists",
      description: "Rico has over 15 years of experience leading product development teams for startups and multinational companies. Rico has been working with blockchain technology since 2017. He is also a ConsenSys - certified blockchain developer. Moreover, Rico is the co-founder of Bicol IT.org., the largest and most active IT advocacy non-profit organization in Southern Luzon."
    },
  ],
  leaders: [
    {
      name: "Andy Agnas",
      image: "andy-agnas.jpg",
      position: "Chief Executive Officer",
      linkedin: "https://www.linkedin.com/in/andrino-agnas-2473a3158",
      team: "The Idealists",
      description: "Andy has been a financial and tax consultant of various companies for over 6 years. A CPA by profession, Andy is the managing owner of his own accounting firm and currently a director of PICPA - Cam Sur Chapter, an active and prestigious professional organization in the Philippines. Andy is also a blockchain enthusiast since 2016, being a resource speaker in various seminars relating to blockchain technology and cryptocurrencies."
    },
    {
      name: "Rico Zuñiga",
      image: "rico-zuniga.jpg",
      position: "Chief Technology Officer",
      linkedin: "https://www.linkedin.com/in/ricoz",
      team: "The Idealists",
      description: "Rico has over 15 years of experience leading product development teams for startups and multinational companies. Rico has been working with blockchain technology since 2017. He is also a ConsenSys - certified blockchain developer. Moreover, Rico is the co-founder of Bicol IT.org., the largest and most active IT advocacy non-profit organization in Southern Luzon."
  }
  ]
};

const Team = () => {
  return (
    <div id="about-us-team" >
      <div className="main-container">
      <div id="about-us-team-leadership">
        <div id="team-main" name="team-main" className="pb-5">
          <Section
            className="meet-the-team custom-section"
            title="Our Story"
            subtitle="Founded in 2018, SparkPoint Technologies Inc. is a startup blockchain company with a global vision. We are a passionate team of innovators dedicated to unlocking the transformative power of blockchain technology with a mission to accelerate the mainstream adoption of blockchain by developing user-friendly products, services, and solutions that empower individuals and businesses to leverage the potential of this revolutionary technology."
          />
          <p className="text-center text-custom-gray fs-5 custom-paragraph">
          We strive to bridge the gap between traditional and decentralized technologies, fostering a more inclusive and transparent digital future for the Philippines and the world.
          </p>
          <p className="text-center text-custom-gray fs-5 custom-paragraph-last">
          From its launch in 2018, SparkPoint has achieved the following milestones:
          </p>
          <div className="py-2 py-lg-0 team-container">
            <Tab.Container id="left-tabs-example" defaultActiveKey="all">
              <Tab.Content>
                <Tab.Pane eventKey="all">
                  <div className="team-wrapper tab-item d-flex flex-wrap justify-content-center align-items-start">
                    {teamList.all.map((allTeam, i) => (
                      <div
                        key={i}
                        className="text-center d-flex flex-column justify-content-center align-items-center portrait-container"
                      >
                        <Fade>
                          <div className="tab-item-wrapper">
                            <a
                              href={allTeam.linkedin}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {
                                allTeam.name === 'Harvey Javier' || allTeam.name === 'Joy Kristi Mae Balde' ? (
                                  <img
                                src={allTeam.image}
                                alt=""
                              />
                                ) : (
                                  <img
                                src={`https://sparkpointio.github.io/api/website/sparkpointio/assets/team/v2/${allTeam.image}`}
                                alt=""
                              />
                                )
                              }
                              <img
                                src={`https://sparkpointio.github.io/api/website/sparkpointio/assets/team/v2/${allTeam.image}`}
                                alt=""
                              />
                            </a>
                          </div>

                          <a
                            href={allTeam.linkedin}
                            target="_blank"
                            rel="noreferrer"
                            className="mt-2 fs-5 text-dark text-decoration-none"
                          >
                            <b>{allTeam.name}</b>
                          </a>
                          <b className="text-custom-blue pt-1 pos">
                            {allTeam.name === 'Mat Ivan Arquero' ? 'Chief Technology Officer' : allTeam.position}
                          </b>

                        </Fade>
                      </div>
                    ))}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Team
