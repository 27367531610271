import { FAQButton, FAQQA } from 'components/custom/Elements'
import sparkswap from 'assets/sparkpoint/ecosystem/SparkSwap.png'
import wallet from 'assets/sparkpoint/ecosystem/Wallet Logo.png'
import './style.scss'

const FAQ = () => {
  return (
    <div id='faq'>
      <div className='faq-menu'>
        <h1 className='faq-title'>Frequently Asked Questions</h1>
        <div className='faq-buttons'>
          <FAQButton idLink='sparkpoint-wallet' src={wallet} value='SparkPoint Wallet' />
          <FAQButton idLink='sparkswap'src={sparkswap} value='SparkSwap' />
          {/*<FAQButton idLink='sparkrewards' src={sparkrewards} value='SparkRewards' />*/}
          {/* <FAQButton idLink='sparkplay' src={sparkplay} value='SparkPlay' /> */}
          {/* <FAQButton idLink='software-development-services' src={developmentServices} value='SparkTech Development Services' /> */}
        </div>
      </div>
      <div className='faq-content'>
        <div id='sparkpoint-wallet'>
          <h1 className='faq-main-title'>SparkPoint Wallet</h1>
          <div className='faq-section'>
            <h2 className='faq-title'>General</h2>
            <FAQQA question='How do I install SparkPoint Wallet?' answer={['Visit ', <a target='_blank' rel='noreferrer' href='https://srk.sh/wallet'>srk.sk/wallet</a>, ' or ', <a target='_blank' rel='noreferrer' href='https://srk.sh/ioswallet'>srk.sh/ioswallet</a>, ' and download the mobile application or go directly to Google Play Store or the Apple App Store.']} />
            <FAQQA question='How can I recover my wallet?' answer={'Choose "recover wallet" then input the seed phrase of your old account. (Note: Be careful when typing the seed phrase. It should be in the same order of words, word space, word format, all lowercase, and don\'t press enter or return after)'} />
            <FAQQA question='Missing tokens after wallet recovery' answer='You have used a different seed phrase. Double check the sequence of words, lower case letters, single spacing, and do not press enter after the last word.' />
            <FAQQA question='Crashing wallet' answer={['You can update teh app on your device\'s respective app store. If the wallet is updated and this issue continues then send us an email at ', <a target='_blank' rel='noreferrer' href='mailto:support@sparkpoint.io'>support@sparkpoint.io</a>, ' and attach the following: a screen recording of the problem, indicate if your device is Android or iOS and what version, and your device name.']} />
          </div>
          <div className='faq-section'>
            <h2 className='faq-title'>Asset Management</h2>
            <FAQQA question='How can I send SRK/other tokens?' answer={'Select the "Send" option on the wallet, enter the recepient\'s address and the amount you\'re going to send. Make sure to have enough ETH/BNB for the gas fees. Remeber to select the options "Slow", "Average", or "Fast".'} />
            <FAQQA question='How can I receive SRK/other tokens?' answer='Select the "Receive" option on the wallet and choose either from the address indicated above the QR code.' />
            <FAQQA question='How can I swap my SRK/other tokens?' answer={'Select the "Trade" option on the wallet and pick either ChangeNOW or Changelly, and enter the desired amount to be swapped and the recepient\'s address. Lastly click the button with the two arrows. Look over your transaction by filtering out the dates for fast tracking.'} />
            <FAQQA question='Can I use a hardware wallet for my SRK?' answer='SRK currently does not support any hardware wallets. Stay tuned for further references.' />
            <FAQQA question='Where can I see my other ALT coins?' answer='You can see them at the ALTS (Other Assets) menu' />
            <FAQQA question='Does the wallet support NFTs?' answer='You can easily manage your NFTs. Users can send, receive, and store their NFT asset securely.' />
          </div>
          <div className='faq-section'>
            <h2 className='faq-title'>Security</h2>
            <FAQQA question='How do I back up my seed phrase?' answer='You can do the following: Write your seed phrase down on a peice of paper, take a picture of it. It is highly recomended to have password vaults for better security. Never give out your seed phrase to anyone.' />
            <FAQQA question='What should I do if I lose my seed phrase?' answer='You can import your wallet to MetaMask by using your private key.' />
            <FAQQA question='Where can I find my seedphrase/private key?' answer='Click on the menu localted on the top left corener of the wallet app and select "Seed Phrase" or "Private Keys".' />
          </div>
        </div>
        <div id='sparkswap'>
          <h1 className='faq-main-title'>SparkSwap</h1>
          <div className='faq-section'>
            <h2 className='faq-title'>Trade</h2>
            <FAQQA question='Where to buy SFUEL?' answer={['You can trade your BNB/BUSD/SRKb to SFUEL on SparkSwap. Visit ', <a target='_blank' rel='noreferrer' href='https://srk.sh/tradesfuel'>srk.sh/tradesfuel</a>, ' for the list of other exchanges.']} />
            <FAQQA question='What is SFUEL' answer='SFUEL is the governance token of SparkSwap.' />
            <FAQQA question='What is Slippage Tolerance?' answer='Slippage tolerance is a setting for the amount of price slippage you are willing to accept for a trade. By setting slippage tolerance, you basically setting a minimum amount on how many tokens you will accept, in the event that the price increases or decreases. ' />
            <FAQQA question= 'What is Annual Percentage Rate (APR)?' answer={['APR is the monetary value or reward that investors may earn by making their crypto tokens accessible for loans, taking into consideration the interest rates and any other fees that borrowers must pay. For more info, read this: ', <a target='_blank' rel='noreferrer' href='https://coinmarketcap.com/alexandria/glossary/annual-percentage-rate-apr'>Annual Percentage Rate/coinmarketcap</a>]} />
          </div>
          <div className='faq-section'>
            <h2 className='faq-title'>Staking</h2>
            <FAQQA question='What is Pool-based Staking or Pools?' answer={['A single-token staking where users earn free tokens as a reward. Read more about it here: ', <a target='_blank' rel='noreferrer' href='https://medium.com/theecosystem/sparkdefi-ataglance-what-is-pool-based-staking-913110fe4545'>[Updated] SparkSwap #AtAGlance: What is SparkPool Pool Staking?</a>]} />
            <FAQQA question='What is Liquidity Staking or Farms?' answer={['Users stake Spark LP tokens to SparkSwap and hold it in a certain period to be rewarded based on the rate and amount of Spark-LP tokens you have staked. Read more about it here: ', <a target='_blank' rel='noreferrer' href='https://medium.com/theecosystem/sparkdefi-ataglance-what-is-liquidity-staking-fcfaf246bdd5'>[Updated] SparkSwap #AtAGlance: What is Liquidity Staking (Farm)?</a>]} />
            <FAQQA question='My tokens disappeared after depositing in your pool' answer='Your token will be in the staking contract until you unstake. Try refreshing the page.' />
          </div>
        </div>
        <div className='faq-section'>
          <h2 className='faq-title'>Bridge</h2>
          <FAQQA question='How to convert my SRK to SRKb and vice versa?' answer={['You may bridgeit on ', <a target='_blank' rel='noreferrer' href='https://app.sparkswap.finance'>https://app.sparkswap.finance</a>, '. You may also visit this article (', <a target='_blank' rel='noreferrer' href='https://medium.com/theecosystem/how-to-bridge-tokens-in-the-new-sparkbridge-interface-58cd0d152241'>How To Bridge Tokens in the New SparkBridge Interface</a>, ') for the full tutorial.']} />
          <FAQQA question={'I have bridged my tokens but haven\'t received them yet'} answer='Bridging may take a few hours before it reflects to your wallet address. Send us an email at defi@sparkpoint.io and provide your transaction hash.' />
        </div>
        <div className='faq-section'>
          <h2 className='faq-title'>Launch</h2>
          <FAQQA question='How can I join your IDOs on SparkLaunch' answer={['To receive allocations, you need to stake a minimun of 50,000 SRKb or 3,500 SFUEL in any of our active pools under Pool-Based Staking (Pools).  Snapshot will be taken 24-48 hours before the sale starts.  Read more about it here: ', <a target='_blank' rel='noreferrer' href='https://medium.com/theecosystem/sparkdefi-ataglance-sparklaunch-will-go-live-this-july-25-140532e2d1e'>SparkDeFi #AtAGlance: SparkLaunch is now LIVE!</a>]} />
          <FAQQA question='When will I receive the tokens I have purchased during the IDO?' answer='Claiming of the tokens will be available after the 24-48 hours hold period or it will be vested to your address. (whichever applies)' />
        </div>
        <div className='faq-section'>
          <h2 className='faq-title'>Airdrop</h2>
          <FAQQA question='How to claim the airdrop' answer={['Here\'s how you can claim:', <ol>
            <li>Switch your wallet's network to BSC Network</li>
            <li>Go to <a target='_blank' rel='noreferrer' href='https://srk.sh/claim'>srk.sh/claim</a></li>
            <li>Connect your wallet then claim</li>
          </ol>, 'For SparkPoint Wallet users, connect your wallet via Wallet Connect.']} />
          <FAQQA question='I tried claiming and paid the gas fee but I did not receive anything.' answer={'Please make sure you have added the token address on your wallet. If it\'s already added, check if your wallet\'s network is correct then try again. '} />
        </div>
        <div className='faq-section'>
          <h2 className='faq-title'>NFT</h2>
          <FAQQA question='How to mint my own NFT?' answer={['Go to ', <a target='_blank' rel='noreferrer' href='https://nft.sparkswap.finance'>nft.sparkswap.finance</a>, ' to mint your NFT. You can watch this video for the full tutorial: ', <a target='_blank' rel='noreferrer' href='https://www.youtube.com/watch?v=8b96e_xJhQE'>How to Mint on #SparkNFT</a>]} />
          <FAQQA question='In which network is SparkNFT?' answer='It is on Binance Smart Chain Network.' />
        </div>
        {
        /*
        <div id='sparkrewards'>
          <h1 className='faq-main-title'>SparkRewards</h1>
          <div className='faq-section'>
            <FAQQA question={'My rewards says it\'s already "earned" but I haven\'t received it in my wallet.'} answer={['Kindly check your wallet address on ', <a href='https://bscscan.com' target='_blank' rel='noreferrer'>bscscan.com</a> ,' then add SRKb token on your wallet.']} />
            <FAQQA question='How can I have my account verified?' answer='SparkRewards (Previously SparkEarn) has transitioned to a KYCless platform, where you only need to complete the email verification to be able to perform bounties.' />
            <FAQQA question='I have pending money on SparkRewards (previously SparkEarn). When can I withdraw them?' answer='To be announced soon.' />
            <FAQQA question='How can I connect my Google Play account to SparkEarn?' answer='The accounts are different. You may use your Google Play Account for the games to record your scores on the leaderboard and you may also use SparkEarn to submit bounties.' />
            <FAQQA question='Error 403' answer='The verification link that was sent to you has already expired. It is advisable to have your email address verified immidiately after registration.' />
          </div>
        </div>
        */
        }
        {/*
          <div id='sparkplay'>
          <h1 className='faq-main-title'>SparkPlay</h1>
          <div className='faq-section'>
            <FAQQA question='Why do I see ads on my games?' answer='Aside from funding the development of our various platforms, the revenue generated from SparkPlay will also be used to buy back SparkPoint (SRK) tokens from exchanges. This buy-back program will be done regularly to maintain the stability of the SRK Token in the open market. Read more about our SRK Buy Back Program.' />
            <FAQQA question='How to earn from playing SparkPlay games?' answer={['SparkPlay games are hyper-casual games but we offer bounties by playing our games on ', <a target='_blank' rel='noreferrer' href='https://earn.sparkpoint.io'>earn.sparkpoint.io</a>]} />
            <FAQQA question='Where can we download your games on iOS?' answer={['Crypto Loco is now available on iOS! Other games will also be available for download on iOS soon. Visit ', <a target='_blank' rel='noreferrer' href='srk.sh/links'>srk.sh/links</a>, ' to download.']} />
            <FAQQA question='How can I connect my wallet? I have registered on SparkEarn already.' answer='Wallet address will be asked when you perform a bounty on SparkEarn. However, bounties for our games (Crypto Burst, Crypto Slicer, Crypto Loco, Crypto Shooter) have already expired.' />
          </div>
        </div>
        */}
        {
          /*
          <div id='software-development-services'>
          <h1 className='faq-main-title'>SparkTech Development Services</h1>
          <div className='faq-section'>
            <FAQQA question='What are the services you offer?' answer={['We offer Smart Contract Development, Game Development, Frontend and Backend Development for Decentralized Finance platform. Let\'s start our discussion! Message us at ', <a target='_blank' rel='noreferrer' href='https://srk.sh/business'>srk.sh/business</a>]} />
            <FAQQA question='What Programming Frameworks do you use?' answer='Front End: React Js, Bootstrap, Gatsby | Backend: Express Js, PHP, Laravel | Database: MySQL, MongoDB | API: REST, GraphQL  | Server: Vercell (API) | Blockchain: Solidity, Truffle | Test: Mocha' />
            <FAQQA question='How to request a price quotation?' answer={['Email us at ', <a target='_blank' rel='noreferrer' href='mailto:business@sparkpoint.io'>business@sparkpoint.io</a>, ' or DM ', <a target='_blank' rel='noreferrer' href='https://t.me/drei_srk'>t.me/drei_srk</a>, ' on telegram.']} />
          </div>
        </div>
          */
        }
      </div>
    </div>
  )
}

export default FAQ
