import { Routes, Route } from 'react-router-dom'
import { useEffect } from 'react'
import {
  Home,
  ComingSoon,
  AboutUs,
  Token,
  Wallet,
  NotFound,
  Partnerships,
  SparkPlay,
  Roadmap,
  Privacy,
  FAQ
} from './routes'
import { Navigation, Footer, Theme } from './components'
import './_root.scss'
import { createStore, action, StoreProvider } from 'easy-peasy'
import Loader from 'components/custom/Loader'
import 'react-toastify/dist/ReactToastify.css'
import CookiebotLoader from 'components/custom/CookiebotLoader'

const axios = require('axios')

const store = createStore({
  theme: {
    currentTheme: document.documentElement.getAttribute('data-theme'),
    setTheme: action((state, payload) => {
      state.currentTheme = payload
    })
  },
  supply: {
    amount: 13.445,
    setAmount: action((state, payload) => {
      state.amount = payload
    }),
    units: 'Billion',
    setUnits: action((state, payload) => {
      state.units = payload
    })
  }
})

function App() {

  const unitsWords = ['Hundred', 'Thousand', 'Million', 'Billion']

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get('https://test-api-cyan.vercel.app/api/v1/jobs/srk')
        const supply = parseInt(response.data.data.SRK[0].total_supply.toString().split('.')[0]).toLocaleString('en-US').replace(/,/g, ".")
        store.getActions().supply.setAmount(supply)

        const supplySplit = supply.split('.').length - 1
        store.getActions().supply.setUnits(unitsWords[supplySplit])
      } catch (error) {
        console.error(error)
      }
    })()
  })
  
  return (
    <StoreProvider store={store}>
      <div className="App">
        <Loader />
        <Navigation />
        <Routes>
          <Route path="*" element={<NotFound />}></Route>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/token" element={<Token />}></Route>
          <Route exact path="/wallet" element={<Wallet />}></Route>
          <Route exact path="/play" element={<SparkPlay />}></Route>
          <Route exact path="/rewards" element={<ComingSoon />}></Route>
          <Route exact path="/roadmap" element={<Roadmap />}></Route>
          <Route exact path="/partnerships" element={<Partnerships />}></Route>
          <Route exact path="/about" element={<AboutUs />}></Route>
          <Route exact path="/privacy" element={<Privacy />}></Route>
          <Route exact path="/faqs" element={<FAQ />}></Route>
        </Routes>
        <Footer />
        <Theme />
        <CookiebotLoader />
      </div>
    </StoreProvider>
  )
}

export default App
