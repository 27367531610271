import './style.scss'

const FAQQA = (props) => {

  const { question, answer } = props
  return (
    <div className='faq-qa'>
      <div className='faq-content-question'>
        <h1>Q.</h1>
        <p>{question}</p>
      </div>
      <div className='faq-content-answer'>
        <h1>A.</h1>
        <p>{answer}</p>
      </div>
    </div>
  )
}

export default FAQQA
