import { useState, useEffect } from 'react'
import { Section } from 'components/custom/Elements'
import Socials from 'components/custom/Socials'
import laptopLight from 'assets/home/communities/Online Communities light.png'
import laptopDark from 'assets/home/communities/Online Communities.png'
import './style.scss'
import { Fade } from 'react-reveal'
import { useStoreState } from 'easy-peasy'

const Communities = () => {
  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [laptop, setLaptop] = useState(
    currentTheme === 'light' ? laptopLight : laptopDark
  )

  useEffect(() => {
    setLaptop(currentTheme === 'light' ? laptopLight : laptopDark)
  }, [currentTheme])

  return (
    <div id="home-communities" name="home-communities" className="component">
      <div className="main-container">
        <div className="d-flex flex-wrap justify-content-center align-items-center gap-5">
          <Fade>
            <div className="communities-image-wrapper d-flex justify-content-center align-items-center">
              <img src={laptop} alt="Online Communities" />
            </div>
          </Fade>
          <div className="communities-info-wrapper d-flex flex-column justify-content-start align-items-start">
            <Section title="Online Communities" className="pb-0" />
            <Fade>
              <p className="lh-lg my-5">
                Stay updated of our latest announcements by subscribing to our
                newsletter and following our social media accounts. Engage and
                stay connected too with our #SparkyCommunity members by joining
                our different community channels.
              </p>
            </Fade>
            <Fade>
              <Socials className="socials gap-4" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Communities
