import { Section } from 'components/custom/Elements'
import srkTokenLight from 'assets/home/token/SRKTokenLight.png'
import srkTokenDark from 'assets/home/token/SRKTokenDark.png'
import ethLogo from 'assets/home/token/eth.png'
import bscLogo from 'assets/home/token/bsc.png'
import srkLogo from 'assets/sparkpoint/SRK.png'
import { Button } from 'components/custom/Elements'
import './style.scss'
import { Link } from 'react-router-dom'
import { Fade } from 'react-reveal'
import { useStoreState } from 'easy-peasy'
import metamask from 'assets/metmask.png'
import Web3 from 'web3'
import Web3Eth from 'web3-eth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

let web3obj
const web3 = window.web3

const Token = () => {

  const supplyRaw = useStoreState((state) => state.supply.amount)
  const supplyRawShort = supplyRaw.toString().slice(0, 6)
  const supply = supplyRaw.toString().slice(0, 4)
  const units = useStoreState((state) => state.supply.units)
  const [copy, setCopy] = useState('Copy')

  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [srkToken, setSrkToken] = useState(
    currentTheme === 'light' ? srkTokenLight : srkTokenDark
  )

  useEffect(() => {
    setSrkToken(currentTheme === 'light' ? srkTokenLight : srkTokenDark)
  }, [currentTheme])

  const addToWalletERC = () => {
    if (typeof ethereum !== 'undefined') {
      web3obj = new Web3(web3.currentProvider);
    } else {
      alert('No web3 provider');
      return
    }

    let network = 0
    web3obj.eth.net.getId((err, netId) => {
      network = netId.toString()

      switch (network) {
        case "1":
          network = 'mainnet';
          break;
        default:
          console.log('unknown network');
      }

      if (network === 'mainnet') {
        try {
          web3obj.eth.currentProvider.sendAsync({
            method: 'wallet_watchAsset',
            params: {
              'type': "ERC20",
              'options': {
                'address': "0x0488401c3f535193fa8df029d9ffe615a06e74e6",
                'symbol': "SRK",
                'decimals': 18,
                'image': 'http://bscscan.com/token/images/sparkpoint_32.png'
              },
            },
            id: Math.round(Math.random() * 100000)
          }, function(err, data) {
            if (!err) {
              data.result ? console.log('Token added') : console.log('error')
            } else {
              console.log(err.message)
            }
          })
        } catch (error) {
          console.error(error)
        }
      } else {
        alert("Please connect to mainnet network")
        return false
      }
    })
  }

  const addToWalletBEP = () => {
    if (typeof ethereum !== 'undefined') {
      web3obj = new Web3Eth(web3.currentProvider);
    } else {
      alert('No web3 provider');
      return
    }

    let network = 0
    web3obj.net.getId((err, netId) => {
      network = netId.toString()

      switch (network) {
        case "56":
          network = 'bsc'
          break
        default:
          console.log('unknown network')
      }

      if (network === 'bsc') {
        try {
          web3obj.currentProvider.sendAsync({
            method: 'wallet_watchAsset',
            params: {
              'type': "ERC20",
              'options': {
                'address': "0xc3440c10c4f36f354eb591b19fafb4906d449b75",
                'symbol': "SRKb",
                'decimals': 18,
                'image': 'http://bscscan.com/token/images/sparkpoint_32.png'
              },
            },
            id: Math.round(Math.random() * 100000)
          }, function(err, data) {
            if (!err) {
              data.result ? console.log('Token added') : console.log('error')
            } else {
              console.log(err.message)
            }
          })
        } catch (error) {
          console.error(error)
        }
      } else {
        alert("Please connect to Binance Smart Chain network")
        return false
      }
    })
  }

  const copyAddress = (address) => {
    const value = document.getElementById(address).innerHTML
    if (navigator.clipboard) {
      navigator.clipboard.writeText(value).then(() => {
        toast.success('Copied!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
        setCopy('Copied!')
        setTimeout(() => {
          setCopy('Copy')
        }, 1000);
      })
      return
    }
    alert('Clipboard API not supported')
  }

  return (
    <div id="home-token" name="home-token">
      <div className="main-container">
      <ToastContainer />
      <div className="d-flex justify-content-center srk-token">
        <Fade>
          <div className="srk-token-image-wrapper">
            <img src={srkToken} alt="SRK Token" />
          </div>
        </Fade>
      </div>
      <Section
        title="The SRK Token"
        subtitle={`The SRK Token is an ERC-20 token deployed on the Ethereum Blockchain with a Binance Smart Chain (SRKb BEP-20) version that can be utilized for staking on SparkPoint's Decentralized Finance Platform, SparkSwap. It has a current total supply of ${supply} ${units} tokens which will be reduced to 10 billion through a series of token burning events. SRK is one of the few cryptocurrency projects from the Philippines that are actively traded on exchanges.`}
      />
      <Fade>
        <div className="chain-wrapper d-flex flex-wrap justify-content-center align-items-center text-center gap-5">
          <div className="ethereum-address-wrapper">
            <div className="ethereum-address">
              <div className="eth d-flex justify-content-center align-items-center gap-3">
                <img src={ethLogo} alt="Ethereum logo" />
                <p>Ethereum &#40;ERC-20&#41;</p>
              </div>
              <p className="text-custom-gray">
                Contract Address
                <span onClick={() => copyAddress('erc20')} className="icon px-2" data-tip data-for='copyAddressERC'>
                  <FontAwesomeIcon icon={faCopy} />
                </span>
                <ReactTooltip id='copyAddressERC' effect='solid'>
                  <span>{copy}</span>
                </ReactTooltip>
              </p>
              <p className="text-custom-blue fs-4 fw-bold" id="erc20">
                0x0488401c3f535193fa8df029d9ffe615a06e74e6
              </p>
              <button onClick={addToWalletERC}>Add to <img src={metamask} alt="MetaMask" /> MetaMask Wallet</button>
            </div>
          </div>
          <div className="binance-address-wrapper">
            <div className="binance-address">
              <div className="bsc d-flex justify-content-center align-items-center gap-3">
                <img src={bscLogo} alt="Binance Smart Chain logo" />
                <p>Binance Smart Chain &#40;BEP-20&#41;</p>
              </div>
              <p className="text-custom-gray">
                Contract Address
                <span onClick={() => copyAddress('bep20')} className="icon px-2" data-tip data-for='copyAddressBEP'>
                  <FontAwesomeIcon icon={faCopy} />
                </span>
                <ReactTooltip id='copyAddressBEP' effect='solid'>
                  <span>{copy}</span>
                </ReactTooltip>
              </p>
              <p className="text-custom-blue fs-4 fw-bold" id="bep20">
                0xc3440c10c4f36f354eb591b19fafb4906d449b75
              </p>
              <button onClick={addToWalletBEP}>Add to <img src={metamask} alt="MetaMask" /> MetaMask Wallet</button>
            </div>
          </div>
        </div>
      </Fade>
      <Fade>
        <div className="token-supply d-flex flex-wrap justify-content-center align-items-center gap-5">
          <img src={srkLogo} alt="SparkPoint logo" />
          <p className="fw-bold fs-1">Current Total Supply</p>
          <div className="text-start lh-sm">
            <b className="text-custom-blue fs-1">{supplyRawShort}+ {units}</b>
            <p className="text-custom-gray">To be reduced to 10 Billion</p>
          </div>
        </div>
        <div className="token-action d-flex justify-content-center">
          <Link to="/token" onClick={() => {
            window.scroll(0, 0)
            document.getElementById('token-button').click()
          }}>
            <Button value="More Information" variant="fill" />
          </Link>
        </div>
      </Fade>
      </div>
    </div>
  )
}

export default Token
