import { useState, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Button } from 'components/custom/Elements'
import contactLight from 'assets/home/contact/contact-light.png'
import contactDark from 'assets/home/contact/contact.png'
import './style.scss'
import { useStoreState } from 'easy-peasy'
import { Fade } from 'react-reveal'
import validator from 'validator'
import { Link } from 'react-router-dom'
import ModalComponent from 'components/custom/Modal'

const axios = require('axios')

const Contact = () => {
  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [contact, setContact] = useState(
    currentTheme === 'light' ? contactLight : contactDark
  )

  const [status, setStatus] = useState('')

  const [sending, setSending] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const [details, setDetails] = useState({
    fullname: '',
    email: '',
    phone: '',
    company: '',
    message: '',
    businessAddress: '',
    attachment: {
      ContentType: '',
      Filename: '',
      Base64Content: ''
    }
  })

  const [showError, setShowError] = useState({
    fullname: false,
    email: false,
    phone: false,
    company: false,
    message: false,
    businessAddress: false
  })

  const [modalShow, setModalShow] = useState(false)

  const handleEmail = (email) => {
    setDetails(prevState => ({...prevState, email: email}))
  }

  const send = async () => {
    setSending(true)
    setIsDisabled(true)
    try {
      await axios.post('https://test-api-cyan.vercel.app/api/v1/business', details)
      setSending(false)
      setDetails({
        fullname: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        businessAddress: '',
        attachment: {
          ContentType: '',
          Filename: '',
          Base64Content: ''
        }
      })
      setModalShow(true)
    } catch (error) {
      console.error(error)
      setStatus(
        <p className="text-danger">
          Failed! Too many attempts. Please try again later.
        </p>
      )
    }
    if(details.isSubscribed) {
      const subscribe = {
        Name: details.fullname,
        Email: details.email
      }
      try {
        await axios.post('https://test-api-cyan.vercel.app/api/v1/subscription', subscribe)
      } catch (error) {
        console.error(error)
      }
    }

    setTimeout(() => {
      setIsDisabled(false)
      setStatus('')
    }, 3000)
  }

  const sendEmail = () => {
    details.email === '' ? setShowError(prevState => ({...prevState, email: true})) : setShowError(prevState => ({...prevState, email: false}))
    !validator.isEmail(details.email) ? setShowError(prevState => ({...prevState, email: true})) : setShowError(prevState => ({...prevState, email: false}))
    details.fullname === '' ? setShowError(prevState => ({...prevState, fullname: true})) : setShowError(prevState => ({...prevState, fullname: false}))
    details.phone === '' ? setShowError(prevState => ({...prevState, phone: true})) : setShowError(prevState => ({...prevState, phone: false}))
    details.company === '' ? setShowError(prevState => ({...prevState, company: true})) : setShowError(prevState => ({...prevState, company: false}))
    details.message === '' ? setShowError(prevState => ({...prevState, message: true})) : setShowError(prevState => ({...prevState, message: false}))
    details.businessAddress === '' ? setShowError(prevState => ({...prevState, businessAddress: true})) : setShowError(prevState => ({...prevState, businessAddress: false}))

    if (
      details.fullname &&
      details.email &&
      details.phone &&
      details.company &&
      details.message !== '' &&
      details.businessAddress !== '' &&
      validator.isEmail(details.email)
    ) {
      send()
    } else {
      setStatus(
        <p className="text-danger">
          Failed! Please check if all information above is correct.
        </p>
      )
    }
  }

  const fileSizeLimit = (size) => {
    if (size < 15000000) {
      return true
    }
    setStatus(
      <p className="text-danger">
        Please upload a file that is not larger than 15MB
      </p>
    )
    return false
  }

  const convertToBase64 = (e) => {
    const selectedFile = e.target.files[0]
    const size = selectedFile.size
    const name = selectedFile.name
    const type = selectedFile.type

    if(e.target.files.length > 0 && fileSizeLimit(size)) {
      const fileReader = new FileReader()
      let base64
      fileReader.onload = function(fileLoadedEvent) {
        base64 = fileLoadedEvent.target.result
        setDetails(prevState => ({...prevState, attachment: {
          ContentType: type,
          Filename: name,
          Base64Content: base64
        }}))
      }
      fileReader.readAsDataURL(selectedFile)
    }
  }

  useEffect(() => {
    setContact(currentTheme === 'light' ? contactLight : contactDark)
  }, [currentTheme])

  return (
    <div id="home-contact" name="home-contact" className="component">
      <ModalComponent title="E-mail sent!" body="Thank you for sending us your inquiry about SparkPoint's SparkTech Development Services. A representative from SparkPoint Business Relations Team will be responding to your inquiry within 24 hours." show={modalShow} onHide={() => setModalShow(false)} />
      <Fade>
      <div className="home-contact-wrapper d-flex flex-wrap justify-content-center align-items-center">
          <Form className="my-5 form-wrapper">
            <h1>Contact a SparkPoint representative</h1>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridMessage">
                <Form.Label>
                  Message
                  <span className={`text-danger ${!showError.message ? 'd-none' : ''}`}>&nbsp;&#42;</span>
                </Form.Label>
                <Form.Control
                  className={showError.message ? 'input-error': ''}
                  as="textarea"
                  rows={3}
                  value={details.message}
                  required
                  id="message"
                  onChange={(e) => {
                    setDetails(prevState => ({...prevState, message: e.target.value}))
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Attachment (pdf)</Form.Label>
                  <Form.Control type="file" onChange={(e) => convertToBase64(e)} accept="application/pdf" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridFullName">
                <Form.Label>
                  Full Name
                  <span className={`text-danger ${!showError.fullname ? 'd-none' : ''}`}>&nbsp;&#42;</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className={showError.fullname ? 'input-error': ''}
                  value={details.firstName}
                  required
                  id="fullname"
                  onChange={(e) => {
                    setDetails(prevState => ({...prevState, fullname: e.target.value}))
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>
                  Email
                  <span className={`text-danger ${!showError.email ? 'd-none' : ''}`}>&nbsp;&#42;</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  className={showError.email ? 'input-error': ''}
                  value={details.email}
                  id="email"
                  required
                  onChange={(e) => {
                    handleEmail(e.target.value)
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCompany">
                <Form.Label>
                  Company
                  <span className={`text-danger ${!showError.company ? 'd-none' : ''}`}>&nbsp;&#42;</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className={showError.company ? 'input-error': ''}
                  value={details.company}
                  required
                  id="company"
                  onChange={(e) => {
                    setDetails(prevState => ({...prevState, company: e.target.value}))
                  }}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridPhone">
                <Form.Label>
                  Phone
                  <span className={`text-danger ${!showError.phone ? 'd-none' : ''}`}>&nbsp;&#42;</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  className={showError.phone ? 'input-error': ''}
                  value={details.phone}
                  required
                  id="phone"
                  onChange={(e) => {
                    setDetails(prevState => ({...prevState, phone: e.target.value}))
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridBusinessAddress">
                <Form.Label>
                  Business Address
                  <span className={`text-danger ${!showError.businessAddress ? 'd-none' : ''}`}>&nbsp;&#42;</span>
                </Form.Label>
                <Form.Control
                  className={showError.businessAddress ? 'input-error': ''}
                  as="textarea"
                  rows={3}
                  value={details.businessAddress}
                  required
                  id="businessAddress"
                  onChange={(e) => {
                    setDetails(prevState => ({...prevState, businessAddress: e.target.value}))
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <p>By submitting this form, I acknowledge that I have read and understand the <Link to="/privacy" onClick={() => window.scrollTo(0, 0)}>SparkPoint Privacy Policy.</Link></p>
            </Row>
            <div id="status">{status}</div>
            <Button
              value={sending ? 'SENDING...' : 'SEND'}
              variant="fill"
              className="w-100 mt-3"
              onClick={sendEmail}
              disabled={isDisabled}
            />
          </Form>
          <div className="home-contact-image">
            <div className="home-contact-image-wrapper">
              <img src={contact} alt="Contact Us" />
            </div>
          </div>
      </div>
      </Fade>
    </div>
  )
}

export default Contact
