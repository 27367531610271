import wallet1 from 'assets/wallet/features/2WideSupport.png'
import wallet2 from 'assets/wallet/features/2GlanceNFT.png'
import wallet3 from 'assets/wallet/features/2CreateRecover.png'
import wallet4 from 'assets/wallet/features/2SeamlessTransfers.png'
import wallet5 from 'assets/wallet/features/2SwapTokens.png'
import wallet6 from 'assets/wallet/features/2ManageConfidence.png'
import './style.scss'
import { Fade } from 'react-reveal'
import { Banner } from 'components'

const Features = () => {
  return (
    <div id="wallet-features">
      <div className="wallet-features-wrapper component d-flex flex-column gap-5">
        <Banner />
        <div className="wallet-features-item d-flex flex-wrap justify-content-between gap-5">
          <div className="wallet-info shadow lh-lg d-flex flex-column justify-content-between">
            <Fade>
              <div className="wallet-info-description">
                <h1 className="text-custom-blue fw-bold lh-lg">
                  Wide Support for Top Tokens
                </h1>
                <p className="text-custom-gray">
                  Create and manage separate wallets for Bitcoin (BTC), Ethereum
                  (ETH) and other ERC-20 based tokens, Binance Coin (BNB BEP-2),
                  Binance Smart Chain (BEP-20) - based tokens. It also natively
                  supports SparkPoint (SRK ERC-20 and SRKb BEP-20), SparkPoint
                  Fuel (SFUEL BEP-20), Binance Coin (BNB BEP-20). Other
                  supported tokens are the top stable coins like Tether USDT
                  (USDT ERC-20), DAI stablecoin (DAI ERC-20), Binance USD (BUSD
                  BEP-20).
                </p>
              </div>
              <div className="wallet-image text-center wallet-image-no-resize">
                <img src={wallet1} alt="Wide support for top tokens" />
              </div>
            </Fade>
          </div>
          <div className="wallet-info shadow lh-lg d-flex flex-column justify-content-between">
            <Fade>
              <div className="wallet-info-description">
                <h1 className="text-custom-blue fw-bold lh-lg">
                  Glance on your NFT collections
                </h1>
                <p className="text-custom-gray">
                  Easily manage your NFTs. Users can send, receive, and store
                  their NFT asset securely.
                </p>
              </div>
              <div className="wallet-image text-center">
                <img src={wallet2} alt="Glance on your NFT collections" />
              </div>
            </Fade>
          </div>
        </div>
        <div className="wallet-features-item-single shadow d-flex flex-wrap justify-content-center">
          <Fade>
            <div className="wallet-features-item-single-info p-5 lh-lg d-flex justify-content-center align-items-center">
              <div className="wallet-features-item-single-info-wrapper">
                <h1 className="text-custom-blue fw-bold">
                  Create and Recover Wallet
                </h1>
                <p className="text-custom-gray">
                  Set up a new wallet account right now on the app or recover
                  your wallet by using your seed phrase.
                </p>
              </div>
            </div>
            <div className="wallet-features-item-single-image">
              <img src={wallet3} alt="Create and Recover Wallet" />
            </div>
          </Fade>
        </div>
        <div className="wallet-features-item d-flex flex-wrap justify-content-between gap-5">
          <Fade>
            <div className="wallet-info-item shadow lh-lg d-flex flex-column justify-content-start">
              <div className="wallet-info-item-wrapper">
                <h1 className="text-custom-blue fw-bold">Seamless Transfers</h1>
                <p className="text-custom-gray">
                  Support swift sending and receiving of listed tokens through
                  QR codes.
                </p>
              </div>
              <div className="wallet-image text-center mt-2">
                <img
                  src={wallet4}
                  alt="Seamless Transfers"
                  className="wallet-image-resized"
                />
              </div>
            </div>
            <div className="wallet-info-item shadow lh-lg d-flex flex-column justify-content-start">
              <div className="wallet-info-item-wrapper">
                <h1 className="text-custom-blue fw-bold">
                  Swap Tokens Instantly
                </h1>
                <p className="text-custom-gray">
                  The built-in decentralized exchanges powered by ChangeNOW,
                  Changelly and Swapzone allow instant swapping of supported
                  tokens and automatically reflects on your wallet's balance.
                </p>
              </div>
              <div className="wallet-image text-center">
                <img src={wallet5} alt="Swap Tokens Instantly" />
              </div>
            </div>
            <div className="wallet-info-item shadow lh-lg d-flex flex-column justify-content-start">
              <div className="wallet-info-item-wrapper">
                <h1 className="text-custom-blue fw-bold">
                  Manage with Confidence
                </h1>
                <p className="text-custom-gray">
                  It's 100% non-custodial. We do not ask and store any of your
                  sensitive personal data on any of our services. Lock your
                  wallets and log-in using your biometrics or security pins.
                </p>
              </div>
              <div className="wallet-image text-center mt-4">
                <img src={wallet6} alt="Manage with Confidence" />
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Features
