import { useEffect } from 'react';

const CookiebotLoader = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'Cookiebot';
    script.src = 'https://consent.cookiebot.com/uc.js';
    script.setAttribute('data-cbid', '2634e716-bbc0-4227-bbc7-6b970bf67803');
    script.setAttribute('data-blockingmode', 'auto');
    script.async = true;

    document.head.appendChild(script);

    return () => {
      // Clean up the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  return null;
};

export default CookiebotLoader;

