import { Section } from 'components/custom/Elements'
import './style.scss'
import welcome1Dark from 'assets/home/welcome/Welcome1Dark.png'
import welcome1Light from 'assets/home/welcome/Welcome1Light.png'
import { useStoreState } from 'easy-peasy'
import { useEffect, useState } from 'react'

const Welcome = () => {
  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [welcome1, setWelcome1] = useState(
    currentTheme === 'light' ? welcome1Light : welcome1Dark
  )

  useEffect(() => {
    setWelcome1(currentTheme === 'light' ? welcome1Light : welcome1Dark)
  }, [currentTheme])

  return (
    <div id="home-welcome" className="component">
      <div className="main-container">
      <Section title="Welcome to SparkPoint Technologies!" />
      <div className="home-welcome-1 d-flex justify-content-center align-items-center gap-5">
        <div className="home-welcome-image-wrapper">
          <div className="home-welcome-image">
            <img src={welcome1} alt="Welcome" />
          </div>
        </div>
        <div className="home-welcome-content">
          <p>SparkPoint is a blockchain technology company launched in 2018 and is one of the first cryptocurrency startups in the Philippines.</p>
          <br />
          <p>We aim to fast-track mainstream adoption of blockchain technology and cryptocurrency by developing products, services and solutions based on it.</p>
        </div>
      </div>
      <br />
      </div>
    </div>
  )
}

export default Welcome
