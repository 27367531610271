import { useEffect, useState } from 'react'
import { Button, Section } from 'components/custom/Elements'
import './style.scss'
import { Fade } from 'react-reveal'

const axios = require('axios')
const regex = /(https?:\/\/[^ ]*)/

const Updates = () => {
  const [feed, setFeed] = useState({
    featured: [],
    all: []
  })

  const convertedDate = (date) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]

    const month = months[new Date(date).getUTCMonth()]
    const day = new Date(date).getUTCDate()
    const year = new Date(date).getUTCFullYear()

    if (isNaN(new Date(date).getUTCDate())) {
      return
    }

    return `${month} ${day}, ${year}`
  }

  const updateFeed = async () => {
    try {
      const response = await axios.get('https://test-api-cyan.vercel.app/api/v1/rss')
      const rss = response.data.items.filter((data) =>
        (data.categories.filter((category) => category === 'updates').length > 0) === true
      )
      setFeed({
        featured: [rss[0]],
        all: rss.slice(1, rss.length)
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    updateFeed()
  }, [])

  return (
    <div id="roadmap-updates" className="lh-lg">
      <div className="main-container">
        <Section title="Our Monthly Updates And Accomplishments" subtitle="SparkPoint revisits the milestones reached through the Monthly Updates and Accomplishments recap." />
        <p className="text-center fs-5 pb-5 mb-5"><a href="https://medium.com/theecosystem" taget="_blank" rel="noreferrer">Be updated of the biggest headlines every month</a>. Read our published blogs now!</p>
        <div className="component">
          <div className="roadmap-updates-featured">
            {feed.featured.map((data, i) => (
              <div key={i} className="roadmap-updates-items d-flex flex-wrap justify-content-start align-items-center gap-5">
                <div className="roadmap-updates-image">
                  <Fade>
                    <a href={data.link} target="_blank" rel="noreferrer">
                      <img src={data['content:encoded'].match(regex)[0].slice(0, -1)} alt="" />
                    </a>
                  </Fade>
                </div>
                <div className="lh-lg roadmap-updates-info">
                  <Fade>
                    <span>{data.creator} | {convertedDate(data.pubDate)}</span>
                    <h1 className="fw-bold">{data.title}</h1>
                    <a href={data.link} target="_blank" rel="noreferrer">
                      <Button title="Read More" variant="fill" />
                    </a>
                  </Fade>
                </div>
              </div>
            ))}
          </div>
          <div className="roadmap-updates-all d-flex flex-wrap justify-content-between align-items-stretch gap-5">
            {feed.all.map((data, i) => (
              <div key={i} className="roadmap-updates-all-wrapper">
                <Fade>
                  <a href={data.link} target="_blank" rel="noreferrer">
                    <img src={data['content:encoded'].match(regex)[0].slice(0, -1)} alt="" />
                  </a>
                  <div>
                    <br />
                    <span>{data.creator} | {convertedDate(data.pubDate)}</span>
                    <h1 className="fw-bold">{data.title}</h1>
                  </div>
                  <a href={data.link} target="_blank" rel="noreferrer">
                    <Button title="Read More" variant="fill" />
                  </a>
                </Fade>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Updates
