import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion } from 'react-bootstrap'
import './style.scss'

const Privacy = () => {
  return (
    <div id='privacy' className='hero-section'>
      <Accordion defaultActiveKey={['0', '1','2']} alwaysOpen id='accordion'>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Privacy Policy</Accordion.Header>
          <Accordion.Body>
            <p>Your privacy is important to us. It is SparkPoint Technologies, Inc.'s policy to respect your privacy regarding any information we may collect from you across our website, https://sparkpoint.io, and other sites we own and operate.</p>
            <p>We only ask for personal information when we truly need it to provide service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
            <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
            <p>We don’t share any personally identifying information publicly or with third parties, except when required to by law.</p>
            <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>
            <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>
            <p>Your continued use of our website will be regarded as an acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>
            <p>This policy is effective as of 4 March 2020.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Terms of Service</Accordion.Header>
          <Accordion.Body>
            <ol>
              <li>
                <h5>Terms</h5>
                <p>By accessing the website at <a href='https://sparkpoint.io'>https://sparkpoint.io</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
              </li>
              <li>
                <h5>Use License</h5>
                <ol type='a'>
                  <li>
                    <p>Permission is granted to temporarily download one copy of the materials (information or software) on SparkPoint Technologies, Inc.'s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
                    <ol type='i'>
                      <li>modify or copy the materials;</li>
                      <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                      <li>attempt to decompile or reverse engineer any software contained on SparkPoint Technologies, Inc.'s website;</li>
                      <li>remove any copyright or other proprietary notations from the materials; or</li>
                      <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                    </ol>
                  </li>
                  <br />
                  <li>
                    <p>This license shall automatically terminate if you violate any of these restrictions and may be terminated by SparkPoint Technologies, Inc. at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>
                  </li>
                </ol>
              </li>
              <li>
                <h5>Disclaimer</h5>
                <ol type='a'>
                  <li>
                    <p>The materials on SparkPoint Technologies, Inc.'s website are provided on an 'as is' basis. SparkPoint Technologies, Inc. makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
                  </li>
                  <li>
                    <p>Further, SparkPoint Technologies, Inc. does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p>
                  </li>
                </ol>
              </li>
              <li>
                <h5>Limitations</h5>
                <p>In no event shall SparkPoint Technologies, Inc. or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on SparkPoint Technologies, Inc.'s website, even if SparkPoint Technologies, Inc. or a SparkPoint Technologies, Inc. authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
              </li>
              <li>
                <h5>Accuracy of materials</h5>
                <p>The materials appearing on SparkPoint Technologies, Inc.'s website could include technical, typographical, or photographic errors. SparkPoint Technologies, Inc. does not warrant that any of the materials on its website are accurate, complete, or current. SparkPoint Technologies, Inc. may make changes to the materials contained on its website at any time without notice. However, SparkPoint Technologies, Inc. does not make any commitment to update the materials.</p>
              </li>
              <li>
                <h5>Links</h5>
                <p>SparkPoint Technologies, Inc. has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by SparkPoint Innovations, Inc. of the site. Use of any such linked website is at the user's own risk.</p>
              </li>
              <li>
                <h5>Modifications</h5>
                <p>SparkPoint Technologies, Inc. may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
              </li>
              <li>
                <h5>Governing Law</h5>
                <p>These terms and conditions are governed by and construed in accordance with the laws of Philippines and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
              </li>
            </ol>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Cookies Policy</Accordion.Header>
          <Accordion.Body>
            <div>
              <p>Last updated: July 09, 2020</p>
              <p>This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can understand what type of cookies We use, or the information We collect using Cookies and how that information is used. This Cookies Policy has been created with the help of the <a href='https://www.termsfeed.com/cookies-policy-generator/' target='_blank' rel='noreferrer'>Cookies Policy Generator</a>.</p>
              <p>Cookies do not typically contain any information that personally identifies a user, but personal information that we store about You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.</p>
              <p>We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.</p>
            </div>
            <div>
              <h3>The use of Cookies</h3>
              <h4>Type of Cookies We Use</h4>
              <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.</p>
              <p>We use both session and persistent Cookies for the purposes set out below:</p>
              <ul>
                <li>
                  <b>Necessary / Essential Cookies</b>
                  <p>Type: Session Cookies</p>
                  <p>Administered by: Us</p>
                  <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
                </li>
                <li>
                  <b>Functionality Cookies</b>
                  <p>Type: Persistent Cookies</p>
                  <p>Administered by: Us</p>
                  <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
                </li>
              </ul>
            </div>
            <div>
              <h4>Your Choices Regarding Cookies</h4>
              <p>If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.</p>
              <p>If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.</p>
              <p>If You'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.</p>
              <ul>
                <li>For the Chrome web browser, please visit this page from Google: <a href='https://support.google.com/accounts/answer/32050' target='_blank' rel='noreferrer'>https://support.google.com/accounts/answer/32050</a></li>
                <li>For the Internet Explorer web browser, please visit this page from Microsoft: <a href='http://support.microsoft.com/kb/278835' target='_blank' rel='noreferrer'>http://support.microsoft.com/kb/278835</a></li>
                <li>For the Firefox web browser, please visit this page from Mozilla: <a href='https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored' target='_blank' rel='noreferrer'>https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></li>
                <li>For the Safari web browser, please visit this page from Apple: <a href='https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac' target='_blank' rel='noreferrer'>https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></li>
              </ul>
              <p>For any other web browser, please visit your web browser's official web pages.</p>
            </div>
            <div>
              <h4>More Information about Cookies</h4>
              <p>You can learn more about cookies: <a href='https://www.termsfeed.com/blog/cookies/' target='_blank' rel='noreferrer'>All About Cookies</a>.</p>
            </div>
            <div>
              <h4>Contact Us</h4>
              <p>
                If you have any questions about this Cookies Policy, You can contact us:
              </p>
              <ul>
                <li>By email: <a href='mailto:support@sparkpoint.io'><FontAwesomeIcon icon={faEnvelope} /> support@sparkpoint.io</a></li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default Privacy
