import './style.scss'

const Button = (props) => {
  const {
    value = 'READ MORE',
    variant = 'no-fill',
    className = '',
    onClick,
    disabled = false
  } = props

  return (
    <button
      className={`button ${variant} ${className} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {value}
    </button>
  )
}

export default Button
