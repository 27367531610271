import { useEffect } from 'react'
import {
  Token,
  Updates,
  News,
  Communities,
  Products,
  Welcome
} from 'components/pages/Home';

import { Hero as AboutHero, Team } from 'components/pages/AboutUs'
import { Hero as RoadmapHero, Carousel } from 'components/pages/Roadmap';

import { Contact } from 'components/pages/Partnerships'

const Home = () => {
  useEffect(() => {
    document.title = `SparkPoint | Home`
    document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', 'home.png')
    document.querySelectorAll('meta[property=twitter\\:image]')[0].setAttribute('content', 'home.png')
  })
  return (
    <>
      <AboutHero />
      <Welcome />
      <Token />
      <Products />
      <Updates />
      <RoadmapHero />
      <Carousel />
      <Team />
      <News />
      <Communities />
      <Contact />
    </>
  )
}

export default Home
