import { Section } from 'components/custom/Elements'
import './style.scss'

const Hero = () => {
  return (
    <div id="partnerships-hero">
      <div
        id="partnerships-hero-bg"
        className="d-flex justify-content-center align-items-center"
      >
        <Section
          title="Partnerships"
          subtitle="SparkPoint's vision and mission won't materialize without the help of different institutions. Our team is always looking for partners whose goal is aligned with ours - Blockchain Technology Awareness, Cryptocurrency Mass Adoption, and Financial Inclusion. Building alliances, programs, and projects to reach all the people and promote the use of blockchain technology."
        />
      </div>
    </div>
  )
}

export default Hero
