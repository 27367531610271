const { faCircleCheck } = require('@fortawesome/free-solid-svg-icons')
const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome')
const { Section } = require('components/custom/Elements')

const Twentytwentytwovertical = () => {
  return (
    <>
      <Section title="2022" />
      <div className="d-flex flex-column w-100 component">
        <div className="d-flex flex-column justify-content-start align-items-start">
          <div className="roadmap-pill-content-simple">
          <ul>
              <li>
                <strong>SparkSwap Enhancements: </strong> Launched SparkSwap V2 with new trading pairs, alongside UI/UX improvements. <FontAwesomeIcon className="text-success" icon={faCircleCheck} />
              </li>
              <li>
                <strong>SparkPoint Wallet Upgrades:</strong> Introduced new features, including multi-wallet creation, fiat integration, and integration with SparkSwap. <FontAwesomeIcon className="text-success" icon={faCircleCheck} />
              </li>
              <li>
                <strong>Community Engagement Initiatives:</strong> Conducted quarterly SRK buyback and burn programs, SFUEL airdrops, and hosted the Sparky NFT Collection Launch.<FontAwesomeIcon className="text-success" icon={faCircleCheck} />
              </li>
              <li>
                <strong>Expansion and Development:</strong> Expanded the team and opened the Blockchain Developer Bootcamp 2022, indicating growth and investment in talent.<FontAwesomeIcon className="text-success" icon={faCircleCheck} />
              </li>
              <li>
                <strong>Ecosystem Development:</strong> Expanded SparkPool with new pools and farms, introduced SFUEL as a bridging fee option, and integrated Polygon Network into the SparkPoint Wallet, reflecting a commitment to ecosystem growth and interoperability.<FontAwesomeIcon className="text-success" icon={faCircleCheck} />
              </li>
            </ul>
          </div>
        </div>
        <hr />
      </div>
    </>
  )
}

export default Twentytwentytwovertical
