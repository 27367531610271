import './style.scss'

const PortfolioCard = (props) => {
  const { obj } = props
  return (
    <div className="portfolio-card">
      <div className="portfolio-card-wrapper">
        <div className="portfolio-card-banner">
          <img src={obj.banner} alt="Banner" />
        </div>
        <div className="portfolio-card-video">
          <iframe width="100%" height="400" src={obj.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div className={`portfolio-card-action ${obj.action.length > 2 && 'small-action'}`}>
          { obj.action.map((el) => {
            return (
              <a href={el.url} target="_blank" rel="noreferrer">
                <img src={el.image} alt="Download" />
              </a>
            )
          }) }
        </div>
      </div>
    </div>
  )
}

export default PortfolioCard
