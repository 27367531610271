import { useEffect, useState } from 'react'
import { Tab, Nav } from 'react-bootstrap'
import { Section } from 'components/custom/Elements'
import srkLogo from 'assets/sparkpoint/SRK.png'
import './style.scss'
import { Fade } from 'react-reveal'
import AliceCarousel from 'react-alice-carousel'

const responsive = {
  0: { items: 1 },
  425: { items: 2 },
  568: { items: 3 },
  1200: { items: 4 },
  1400: { items: 5 },
  1500: { items: 8 }
}

const axios = require('axios')

const Exchanges = () => {
  const [data, setData] = useState({
    TradeSRK: [],
    SRKETH: [],
    SRKBTC: [],
    SRKUSDT: [],
    SRKbBNB: [],
    SRKbBUSD: []
  })

  const [secondaryExchangeList, setSecondaryExchangeList] = useState([])

  const loadExchanges = async () => {
    try {
      const response = await axios.get(
        'https://sparkpointio.github.io/api/website/sparkpointio/exchanges/'
      )
      const TradeSRKArray = response.data?.primary
      const SRKETHArray = response.data?.primary.filter((list) =>
        list.pairs.some((pairs) => pairs.name === 'SRK/ETH')
      )
      const SRKBTCArray = response.data?.primary.filter((list) =>
        list.pairs.some((pairs) => pairs.name === 'SRK/BTC')
      )
      const SRKUSDTArray = response.data?.primary.filter((list) =>
        list.pairs.some((pairs) => pairs.name === 'SRK/USDT')
      )
      const SRKbBNBArray = response.data?.primary.filter((list) =>
        list.pairs.some((pairs) => pairs.name === 'SRKb/BNB')
      )
      const SRKbBUSDArray = response.data?.primary.filter((list) =>
        list.pairs.some((pairs) => pairs.name === 'SRKb/BUSD')
      )
      setData({
        TradeSRK: TradeSRKArray,
        SRKETH: SRKETHArray,
        SRKBTC: SRKBTCArray,
        SRKUSDT: SRKUSDTArray,
        SRKbBNB: SRKbBNBArray,
        SRKbBUSD: SRKbBUSDArray
      })

      setSecondaryExchangeList(response.data?.secondary)
    } catch (error) {
      console.error(error)
    }
  }

  const items = secondaryExchangeList.map((partner, i) => (
    <div key={i} className="partnersWrapper d-flex justify-content-center align-items-center">
      <a
        className="partnersLink text-center"
        href={partner.url ?? ''}
        target="_blank"
        rel="noreferrer"
      >
        <Fade>
          <img
            className="partnersImage custom-width"
            src={`https://sparkpointio.github.io/api/website/sparkpointio/assets/exchanges/${partner.image}`}
            alt="Partner"
          />
        </Fade>
      </a>
    </div>
  ))

  useEffect(() => {
    loadExchanges()
  }, [])

  return (
    <div id="token-exchanges" name="token-exchanges" className="component">
      <div className="token-exchanges-title d-flex justify-content-center">
        <Section title="Where to get SRK" />
        <Fade>
          <span>
            <img src={srkLogo} alt="SparkPoint" />
          </span>
        </Fade>
      </div>
      <div>
        <Fade>
          <Tab.Container id="left-tabs-example" defaultActiveKey="all">
            <Nav
              variant="pills"
              className="d-flex flex-wrap justify-content-center align-items-center pills-container"
            >
              <Nav.Item className="pointer">
                <Nav.Link eventKey="all">All</Nav.Link>
              </Nav.Item>
              <Nav.Item className="pointer">
                <Nav.Link eventKey="eth">SRK/ETH</Nav.Link>
              </Nav.Item>
              <Nav.Item className="pointer">
                <Nav.Link eventKey="btc">SRK/BTC</Nav.Link>
              </Nav.Item>
              <Nav.Item className="pointer">
                <Nav.Link eventKey="usdt">SRK/USDT</Nav.Link>
              </Nav.Item>
              <Nav.Item className="pointer">
                <Nav.Link eventKey="bnb">SRKb/BNB</Nav.Link>
              </Nav.Item>
              <Nav.Item className="pointer">
                <Nav.Link eventKey="busd">SRKb/BUSD</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item className="pointer">
                <Nav.Link eventKey="others">Others</Nav.Link>
              </Nav.Item> */}
            </Nav>
            <hr />
            <Tab.Content>
              <Tab.Pane eventKey="eth">
                <div className="tab-item d-flex flex-wrap justify-content-center align-items-center">
                  {data.SRKETH.map((exchange, i) => (
                    <div
                      key={i}
                      className="tab-item-wrapper shadow text-center d-flex flex-wrap justify-content-center align-self-stretch gap-3"
                    >
                      <a
                        href={exchange.url}
                        target="_blank"
                        rel="noreferrer"
                        className="image-wrapper d-flex justify-content-center align-items-center"
                      >
                        <img
                          src={`https://sparkpointio.github.io/api/website/sparkpointio/assets/exchanges/${exchange.image}`}
                          className="p-3"
                          alt=""
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="btc">
                <div className="tab-item d-flex flex-wrap justify-content-center align-items-center p-5">
                  {data.SRKBTC.map((exchange, i) => (
                    <div
                      key={i}
                      className="tab-item-wrapper shadow text-center d-flex flex-wrap justify-content-center align-self-stretch gap-3"
                    >
                      <a
                        href={exchange.url}
                        target="_blank"
                        rel="noreferrer"
                        className="image-wrapper d-flex justify-content-center align-items-center"
                      >
                        <img
                          src={`https://sparkpointio.github.io/api/website/sparkpointio/assets/exchanges/${exchange.image}`}
                          className="p-3"
                          alt=""
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="usdt">
                <div className="tab-item d-flex flex-wrap justify-content-center align-items-center p-5">
                  {data.SRKUSDT.map((exchange, i) => (
                    <div
                      key={i}
                      className="tab-item-wrapper shadow text-center d-flex flex-wrap justify-content-center align-self-stretch gap-3"
                    >
                      <a
                        href={exchange.url}
                        target="_blank"
                        rel="noreferrer"
                        className="image-wrapper d-flex justify-content-center align-items-center"
                      >
                        <img
                          src={`https://sparkpointio.github.io/api/website/sparkpointio/assets/exchanges/${exchange.image}`}
                          className="p-3"
                          alt=""
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="bnb">
                <div className="tab-item d-flex flex-wrap justify-content-center align-items-center p-5">
                  {data.SRKbBNB.map((exchange, i) => (
                    <div
                      key={i}
                      className="tab-item-wrapper shadow text-center d-flex flex-wrap justify-content-center align-self-stretch gap-3"
                    >
                      <a
                        href={exchange.url}
                        target="_blank"
                        rel="noreferrer"
                        className="image-wrapper d-flex justify-content-center align-items-center"
                      >
                        <img
                          src={`https://sparkpointio.github.io/api/website/sparkpointio/assets/exchanges/${exchange.image}`}
                          className="p-3"
                          alt=""
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="busd">
                <div className="tab-item d-flex flex-wrap justify-content-center align-items-center p-5">
                  {data.SRKbBUSD.map((exchange, i) => (
                    <div
                      key={i}
                      className="tab-item-wrapper shadow text-center d-flex flex-wrap justify-content-center align-self-stretch gap-3"
                    >
                      <a
                        href={exchange.url}
                        target="_blank"
                        rel="noreferrer"
                        className="image-wrapper d-flex justify-content-center align-items-center"
                      >
                        <img
                          src={`https://sparkpointio.github.io/api/website/sparkpointio/assets/exchanges/${exchange.image}`}
                          className="p-3"
                          alt=""
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="all">
                <div className="tab-item d-flex flex-wrap justify-content-center align-items-center p-5">
                  {data.TradeSRK.map((exchange, i) => (
                    <div
                      key={i}
                      className="tab-item-wrapper shadow text-center d-flex flex-wrap justify-content-center align-self-stretch gap-3"
                    >
                      <a
                        href={exchange.url}
                        target="_blank"
                        rel="noreferrer"
                        className="image-wrapper d-flex justify-content-center align-items-center"
                      >
                        <img
                          src={`https://sparkpointio.github.io/api/website/sparkpointio/assets/exchanges/${exchange.image}`}
                          className="p-3"
                          alt=""
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Fade>
      </div>
      <div className="partnersList">
        <AliceCarousel
          responsive={responsive}
          mouseTracking
          items={items}
          controlsStrategy="alternate"
          disableButtonsControls={true}
          disableDotsControls={false}
          infinite={true}
          autoPlay={true}
          autoPlayInterval="1500"
        />
      </div>
    </div>
  )
}

export default Exchanges
