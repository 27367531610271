import { Section } from 'components/custom/Elements'
import ap from 'assets/home/news/ap.png'
import bitmart from 'assets/home/news/bitmart.png'
import bitPinas from 'assets/home/news/bitpinas.png'
import bitPinas2 from 'assets/home/news/bitpinas2.png'
import coinQuora from 'assets/home/news/coin-quora.png'
import coinTelegraph from 'assets/home/news/cointelegraph.png'
import cryptoPotato from 'assets/home/news/crypto-potato.png'
import theBlock from 'assets/home/news/the-block.png'
import yahooFinance from 'assets/home/news/yahoo-finance.png'
import './style.scss'
import { Fade } from 'react-reveal'

const News = () => {
  return (
    <div id="home-news" name="home-news" className="component">
      <div className="main-container">
      <Section title="On The News" />
      <Fade>
        <div className="news-items d-flex flex-wrap justify-content-center align-items-center gap-4">
          <div className="news-item">
            <a
              href="https://support.bmx.fund/hc/en-us/articles/1260801288510"
              target="_blank"
              rel="noreferrer"
              className="news-item-image-wrapper bg-custom-gray d-flex justify-content-center align-items-center"
            >
              <img src={bitmart} alt="BitMart" />
            </a>
          </div>
          <div className="news-item">
            <a
              href="https://apnews.com/press-release/globenewswire-mobile/technology-business-financial-markets-diseases-and-conditions-financial-technology-8e019804fbc5de5e028d37cb0a23c9ea"
              target="_blank"
              rel="noreferrer"
              className="news-item-image-wrapper bg-custom-gray d-flex justify-content-center align-items-center"
            >
              <img src={ap} alt="AP" />
            </a>
          </div>
          <div className="news-item">
            <a
              href="https://finance.yahoo.com/news/2021-blockchain-influencers-prediction-bitcoin-220000800.html"
              target="_blank"
              rel="noreferrer"
              className="news-item-image-wrapper bg-custom-gray d-flex justify-content-center align-items-center"
            >
              <img src={yahooFinance} alt="Yahoo Finance" />
            </a>
          </div>
          <div className="news-item">
            <a
              href="https://cointelegraph.com/press-releases/bitmart-cryptos-of-the-year-top-10-coins-with-huge-potential-in-2021"
              target="_blank"
              rel="noreferrer"
              className="news-item-image-wrapper bg-custom-gray d-flex justify-content-center align-items-center"
            >
              <img src={coinTelegraph} alt="Coin Telegraph" />
            </a>
          </div>
          <div className="news-item">
            <a
              href="https://coinquora.com/sparkpoint-srk-review-a-rising-star-for-wallet-defi-games/"
              target="_blank"
              rel="noreferrer"
              className="news-item-image-wrapper bg-custom-gray d-flex justify-content-center align-items-center"
            >
              <img src={coinQuora} alt="Coin Quora" />
            </a>
          </div>
          <div className="news-item">
            <a
              href="https://bitpinas.com/feature/local-action-national-vision-2019-blockchain-cryptocurrency-bicol-year-review/"
              target="_blank"
              rel="noreferrer"
              className="news-item-image-wrapper bg-custom-gray d-flex justify-content-center align-items-center"
            >
              <img src={bitPinas} alt="BitPinas" />
            </a>
          </div>
          <div className="news-item">
            <a
              href="https://blockchaintechnology-news.com/2021/01/bitmarts-2020-cryptos-of-the-year-report-lrc-ewt-inj-among-tokens-nominated/"
              target="_blank"
              rel="noreferrer"
              className="news-item-image-wrapper bg-custom-gray d-flex justify-content-center align-items-center"
            >
              <img src={theBlock} alt="The Block" />
            </a>
          </div>
          <div className="news-item">
            <a
              href="https://cryptopotato.com/bitmart-published-cryptos-of-the-year-report-on-top-10-altcoins-for-2021/"
              target="_blank"
              rel="noreferrer"
              className="news-item-image-wrapper bg-custom-gray d-flex justify-content-center align-items-center"
            >
              <img src={cryptoPotato} alt="Crypto Potato" />
            </a>
          </div>
          <div className="news-item">
            <a
              href="https://bitpinas.com/business/filipino-led-crypto-startups-to-watch-out-for/"
              target="_blank"
              rel="noreferrer"
              className="news-item-image-wrapper bg-custom-gray d-flex justify-content-center align-items-center"
            >
              <img src={bitPinas2} alt="BitPinas" />
            </a>
          </div>
        </div>
      </Fade>
      </div>
    </div>
  )
}

export default News
