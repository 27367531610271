import { scroller } from 'react-scroll'
import './style.scss'

const FAQButton = (props) => {
  const { src, value, idLink } = props
  
  const goTo = (_id) => {
    scroller.scrollTo(_id, {
      duration: 300,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -200
    })
  }

  return (
    <div className="faq-button-menu" onClick={() => goTo(idLink)}>
      <img src={src} alt={value} />
      <p>{value}</p>
    </div>
  )
}

export default FAQButton
