import { useEffect } from 'react'
import {
  Hero,
  Portfolio,
  Affiliates
} from 'components/pages/SparkPlay'

const SparkPlay = () => {
  useEffect(() => {
    document.title = `SparkPoint | SparkPlay`
  })
  return (
    <>
      <Hero />
      <Portfolio />
      <Affiliates />
    </>
  )
}

export default SparkPlay
