import sgvWhite from 'assets/partnerships/partners/logo/sgv.png'
import sgvBlack from 'assets/partnerships/partners/logo/sgv-black.png'
import ownly from 'assets/about-us/affiliates/Ownly.png'
import popsiclegames from 'assets/partnerships/partners/logo/popsicle.png'
import changenow from 'assets/partnerships/partners/logo/changenow.png'
import nowpayments from 'assets/partnerships/partners/logo/nowpayments.png'
import bicolit from 'assets/partnerships/partners/logo/bicolit.png'
import web3ph from 'assets/partnerships/partners/logo/web3ph.png'
import gdap from 'assets/partnerships/partners/logo/gdap.png'
import hakanaga from 'assets/partnerships/partners/logo/hakanaga.png'
import { Button } from 'components/custom/Elements'
import './style.scss'
import { useStoreState } from 'easy-peasy'
import { useEffect, useState } from 'react'
import axios from 'axios'
import AliceCarousel from 'react-alice-carousel'
import { Fade } from 'react-reveal'

const responsive = {
  0: { items: 1 },
  425: { items: 2 },
  568: { items: 3 },
  1200: { items: 4 },
  1400: { items: 5 },
  1500: { items: 8 }
}

const Partners = () => {
  const currentTheme = useStoreState((state) => state.theme.currentTheme)
  const [partnersList, setPartnersList] = useState([])

  const [sgv, setSgv] = useState(currentTheme === 'light' ? sgvBlack : sgvWhite)

  const getPartners = async () => {
    try {
      const response = await axios.get(
        'https://sparkpointio.github.io/api/website/sparkpointio/partners/'
      )
      const newPartnersList = response.data.filter(
        (list) => list.name.split(' ').join('').toLowerCase() !== 'ownly' && list.name.split(' ').join('').toLowerCase() !== 'sparkpointglobalventures' && list.name.split(' ').join('').toLowerCase() !== 'popsiclegames' && list.name.split(' ').join('').toLowerCase() !== 'changenow' && list.name.split(' ').join('').toLowerCase() !== 'nowpayments' && list.name.split(' ').join('').toLowerCase() !== 'bicolit'
      )
      setPartnersList(newPartnersList)
    } catch (error) {
      console.error(error)
    }
  }

  const items = partnersList.map((partner, i) => (
    <div className="partnersWrapper d-flex justify-content-center align-items-center">
      <a
        className="partnersLink text-center"
        href={partner.url ?? ''}
        target="_blank"
        rel="noreferrer"
      >
        <Fade>
          <img
            className="partnersImage"
            src={`https://sparkpointio.github.io/api/website/sparkpointio/assets/partners/${partner.image}`}
            alt="Partner"
          />
          <p>{partner.name}</p>
        </Fade>
      </a>
    </div>
  ))

  useEffect(() => {
    setSgv(currentTheme === 'light' ? sgvBlack : sgvWhite)
  }, [currentTheme])

  useEffect(() => {
    getPartners()
  }, [])

  return (
    <div id="partnerships-partners">
      <div className="partnerships-partners-item-wrapper d-flex flex-wrap justify-content-start align-items-stretch">
        <div className="partnerships-partners-item text-center w-100">
          <div className="partnerships-partners-item-outer">
            <div className="partnerships-partners-item-inner">
              <Fade>
                <div className="d-flex justify-content-center">
                  <img src={sgv} alt="SparkPoint Global Ventures" />
                </div>
                <p className="fw-bold fs-3 pt-4 pb-1">
                  SparkPoint Global Ventures
                </p>
                <div className="pb-3 d-flex justify-content-center align-items-center">
                  <p className="w-50">
                    SparkPoint Global Ventures Inc. was established in 2021 in
                    the Philippines as part of the overall SparkPoint project's
                    vision of global expansion and diversification. SGVI invests
                    and incubates cryptocurrency projects through private and
                    seed rounds. Performs a hands-on approach and shares
                    experiences and other resources to minimize risk and make
                    success more attainable for their portfolio projects. 
                    If you're interested in doing business with SparkPoint Global Ventures Inc., contact them through their website.
                  </p>
                </div>
                <a
                  href="https://sparkpoint.global/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button value="Visit Website" variant="fill" />
                </a>
              </Fade>
            </div>
          </div>
        </div>
        <div className="partnerships-partners-item text-center">
          <div className="partnerships-partners-item-outer">
            <div className="partnerships-partners-item-inner">
              <Fade>
                <div className="d-flex justify-content-center">
                  <img src={ownly} alt="Ownly" />
                </div>
                <p className="fw-bold fs-3 pt-4 pb-1">Ownly</p>
                <p className="pb-3">
                  Ownly is a metaverse-focused platform that allows creators and
                  collectors to optimize ownership and utility through
                  innovative applications of non-fungible tokens (NFT). One of
                  the key features of this project is Empowering Creators. Ownly
                  supports their arts, fills in the gap of the technology and
                  marketing side of NFTs. As part of a strong and sustainable
                  strategic partnership with SparkPoint, Ownly conducted its
                  Initial DEX Offering (IDO) on SparkLaunch and successfully
                  raised the total target fund for their project. OWN tokens
                  staking pools are also available on SparkPool.
                </p>
                <a href="https://ownly.io/" target="_blank" rel="noreferrer">
                  <Button value="Explore Ownly's NFTs" variant="fill" />
                </a>
              </Fade>
            </div>
          </div>
        </div>
        <div className="partnerships-partners-item text-center">
          <div className="partnerships-partners-item-outer">
            <div className="partnerships-partners-item-inner">
              <Fade>
                <div className="d-flex justify-content-center">
                  <img src={popsiclegames} alt="Popsicle Games" />
                </div>
                <p className="fw-bold fs-3 pt-4 pb-1">Popsicle Games</p>
                <p className="pb-3">
                  Creating fun, bite-sized games that can be developed in short,
                  focused bursts. Pixelkit (and with it, the Popsicle Games
                  brand) has been steadily gaining recognition in the local
                  games industry, with various appearances on national media and
                  a growing presence in local game-related events. Our Crypto
                  Loco Game, marks as the first ever project made Popsicle
                  Games.
                </p>
                <div className="d-flex flex-wrap justify-content-center align-items-center gap-4">
                  <a
                    href="https://anitolegends.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button value="Play Anito Legends" variant="fill" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=io.sparkpoint.cryptoloco"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button value="Play Crypto Loco" variant="fill" />
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="partnerships-partners-item text-center">
          <div className="partnerships-partners-item-outer">
            <div className="partnerships-partners-item-inner">
              <Fade>
                <div className="d-flex flex-wrap justify-content-center gap-3">
                  <img src={changenow} alt="ChangeNOW and NOWPayments" />
                  <img src={nowpayments} alt="ChangeNOW and NOWPayments" />
                </div>
                <p className="fw-bold fs-3 pt-4 pb-1">
                  ChangeNOW and NOWPayments
                </p>
                <p className="pb-3">
                  ChangeNOW is a non-custodial service created for simple and
                  fast cryptocurrency exchanges. It is one of the built-in
                  Exchanges on SparkPoint Wallet App that supports multiple
                  cryptocurrencies. Meanwhile, NOWPayments makes various
                  use-cases for $SRK as payment or donations possible through
                  merchant plug-ins.
                </p>
                <div className="d-flex flex-wrap justify-content-center align-items-center gap-4">
                  <a
                    href="https://changenow.io/?from=eth&to=srk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button value="Trade for SRK" variant="fill" />
                  </a>
                  <a
                    href="https://nowpayments.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button value="Accept SRK Payments" variant="fill" />
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="partnerships-partners-item text-center">
          <div className="partnerships-partners-item-outer">
            <div className="partnerships-partners-item-inner">
              <Fade>
                <div className="d-flex justify-content-center">
                  <img src={bicolit} alt="Bicol IT" />
                </div>
                <p className="fw-bold fs-3 pt-4 pb-1">BicolIT.org</p>
                <p className="pb-3">
                  A non-profit organization with a mission to educate the
                  Bicolanos with the emerging Blockchain Industry.
                </p>
                <a href="https://bicolit.org/" target="_blank" rel="noreferrer">
                  <Button value="Visit Website" variant="fill" />
                </a>
              </Fade>
            </div>
          </div>
        </div>
        <div className="partnerships-partners-item text-center w-100">
          <div className="partnerships-partners-item-outer">
            <div className="partnerships-partners-item-inner">
              <Fade>
                <div className="d-flex justify-content-center">
                  <img src={web3ph} alt="Web3 Philippines" />
                </div>
                <p className="fw-bold fs-3 pt-4 pb-1">
                  Web3 Philippines
                </p>
                <div className="pb-3 d-flex justify-content-center align-items-center">
                  <p className="w-50">
                    Web3 Philippines is the first and primary Web3 tech and non-tech builder community in the country, helping the Filipinos build in Web3 space.
                  </p>
                </div>
                <a
                  href="https://web3philippines.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button value="Visit Website" variant="fill" />
                </a>
              </Fade>
            </div>
          </div>
        </div>
        <div className="partnerships-partners-item text-center">
          <div className="partnerships-partners-item-outer">
            <div className="partnerships-partners-item-inner">
              <Fade>
                <div className="d-flex justify-content-center">
                  <img src={gdap} alt="Game Developers Association of the Philippines (GDAP)" />
                </div>
                <p className="fw-bold fs-3 pt-4 pb-1">Game Developers Association of the Philippines (GDAP)</p>
                <p className="pb-3">
                  The Game Developers Association of the Philippines (GDAP) is a trade association in the Philippines, established in 2007 to represent and promote the country's game development industry.
                </p>
                <a href="https://gdap.org.ph/" target="_blank" rel="noreferrer">
                  <Button value="Visit Website" variant="fill" />
                </a>
              </Fade>
            </div>
          </div>
        </div>
        <div className="partnerships-partners-item text-center">
          <div className="partnerships-partners-item-outer">
            <div className="partnerships-partners-item-inner">
              <Fade>
                <div className="d-flex justify-content-center">
                  <img src={hakanaga} alt="HAKA NAGA" />
                </div>
                <p className="fw-bold fs-3 pt-4 pb-1">HAKA NAGA</p>
                <p className="pb-3">
                  HAKA NAGA is a non-profit organization that officially represents the Naga City startup community. It aims to foster technopreneurship in the Bicol Region and beyond. As a non-profit organization, their vision is to create an inclusive ecosystem that encourages startup founders from both local and global origins to thrive.
                </p>
                <div className="d-flex flex-wrap justify-content-center align-items-center gap-4">
                  <a
                    href="https://www.facebook.com/HarongKatuodNaga/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button value="Visit Page" variant="fill" />
                  </a>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="partnersList">
        <AliceCarousel
          responsive={responsive}
          mouseTracking
          items={items}
          controlsStrategy="alternate"
          disableDotsControls={true}
          infinite={true}
          autoPlay={true}
          autoPlayInterval="1000"
        />
      </div>
    </div>
  )
}

export default Partners
