import { useEffect } from 'react'
import { Hero, Roadmapyears, Updates } from 'components/pages/Roadmap'

const Roadmap = () => {
  useEffect(() => {
    document.title = `SparkPoint | Roadmap`
    document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', 'roadmap.png')
    document.querySelectorAll('meta[property=twitter\\:image]')[0].setAttribute('content', 'roadmap.png')
  })
  return (
    <>
      <Hero />
      <Roadmapyears />
      <Updates />
    </>
  )
}

export default Roadmap
